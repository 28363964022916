import * as yup from 'yup'
import { Objective } from '../TreatmentPlan/slice/types'
export const parentTaskFormValidator = yup.object().shape({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  objective: yup
    .object()
    .nullable()
    .test('objective', 'Objective is required', (value: Objective | null, context) => {
      if (context?.parent?.checked) {
        return !!value?.id
      }
      return true
    }),
})
