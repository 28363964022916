// Core
import { object, string, bool, shape } from 'prop-types'
import { useState } from 'react'
// Styles
import Styles from './styles.module.scss'
// Images
import errorIcon from '../../../theme/assets/icons/error.svg'

export const TextField = (props) => {
  if (!props.register) return null

  return (
    <div className={`${Styles.text_wrapper} ${props?.class}`}>
      {props.label && (
        <label>
          {props.label}
          <span>{props?.requiredIcon}</span>
        </label>
      )}
      <input
        {...props.register}
        value={props?.register?.value ?? props?.value}
        autoComplete="off"
        type={props.type}
        autoFocus={props?.autoFocus}
        className={props?.inputClass}
        placeholder={props?.placeholder}
        disabled={props?.disabled}
        onChange={(e) => {
          props?.register?.onChange(e)
          if (props?.onTextChange) props?.onTextChange(e?.target?.value)

          if (props?.onBlur) props?.onBlur(e)
        }}
        onBlur={props?.onBlur}
        onWheel={(e) => {
          if (props.type === 'number') {
            e.target.blur()
          }
        }}
      />
      {props?.error && (
        <div className={`${Styles.error_wraper} ${props.error_wraper}`}>
          {props?.error ? <img className={Styles.error_icon} src={errorIcon} alt={'error'} /> : null}
          <div className={Styles.error}>{props?.error ? <span>{props.error?.message || 'error'}</span> : null}</div>
        </div>
      )}
    </div>
  )
}

TextField.defaultProps = {
  type: 'text',
}

TextField.propTypes = {
  register: object,
  label: string,
  class: string,
  requiredIcon: string,
  type: string,
  autoFocus: bool,
  inputClass: string,
  error_wraper: string,
  placeholder: string,
  disabled: bool,
  error: shape({
    message: string,
  }),
}
