import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import { useEffect, useMemo } from 'react'
import type { Objective } from './slice/types'
import { Box } from 'components/Core'
import type { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form'

type Props = {
  profile_id: number | undefined
  objectiveValue: Objective | undefined
  modalOpened: boolean
  selectedObjectives: Array<number | undefined>
  updateProgress: (objective: Objective) => void
  objectives: Objective[] | undefined
  disabled?: boolean
  name: string
  register: UseFormRegister<FieldValues>
  setValue: UseFormSetValue<FieldValues>
}
export function ObjectivesSelector({ selectedObjectives, objectiveValue, disabled, updateProgress, objectives, name, register, setValue }: Props) {
  const options = useMemo(() => {
    if (!objectives) return []
    return objectives
      ?.map((item) => {
        return {
          ...item,
          goalTitle: item.goal?.title,
        }
      })
      ?.filter((item) => !selectedObjectives.includes(item.id as number))
  }, [objectives, selectedObjectives])
  return (
    <Box>
      <Autocomplete
        disabled={disabled}
        //@ts-ignore
        options={options?.sort((a, b) => -b?.goalTitle?.localeCompare(a?.goalTitle as string))}
        groupBy={(option) => option?.goalTitle ?? ('' as string)}
        getOptionLabel={(option) => option?.title ?? ''}
        sx={{ width: '100%', backgroundColor: disabled ? 'transparent' : 'white' }}
        renderInput={(params) => <TextField {...params} label="Objective" {...register(name)} />}
        onChange={(_, value) => {
          updateProgress(value!)
          setValue(name, value, { shouldValidate: true })
        }}
        value={{ ...objectiveValue, goalTitle: objectiveValue?.goal?.title }}
        aria-required
        // clearOnBlur
      />
    </Box>
  )
}
