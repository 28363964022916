import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/type'

const rootState = (state: RootState) => state

export const selectDefaultLayout = createSelector([rootState], (state) => state.defaultLayout)

export const selectAuthState = createSelector([rootState], (state) => state?.auth)
export const selectUser = createSelector([selectAuthState], (state) => state?.user)
export const selectUserType = createSelector([selectAuthState], (state) => state?.user?.user?.user_type)

export const selectAlertState = createSelector([selectDefaultLayout], (state) => state.alert)
export const selectZoomLeaveUrl = createSelector([selectDefaultLayout], (state) => state.zoomLeaveUrl)
export const selectABTestingState = createSelector([selectDefaultLayout], (state) => state.abTesting)

export const selectOpenedTab = createSelector([selectDefaultLayout], (state) => state.openedTab)
