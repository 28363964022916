// Core
import { useState } from 'react'
import { useSelector } from 'react-redux'
// Routing
import { NavLink } from 'react-router-dom'
// Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as EnvelopIcon } from '../../../theme/assets/icons/envelop-animated-icon.svg'

export const AdminNav = ({ isIconShow }) => {
  const user = useSelector((state) => state.auth.user)

  return (
    <div className={Styles.tabs_container}>
      <div className={Styles.tab_wrapper}>
        {(user?.user?.user_type === 'advisor' || user?.user?.user_type === 'admin') && (
          <NavLink to={`/admin_panel/${user?.user?.user_type}/therapists`} className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
            <span>{'Therapists'}</span>
          </NavLink>
        )}
      </div>
      {user?.user?.user_type === 'advisor' && (
        <div className={Styles.tab_wrapper}>
          <NavLink to={`/admin_panel/${user?.user?.user_type}/profiles`} className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
            <span>{'Profiles'}</span>
          </NavLink>
        </div>
      )}
      {user?.user?.user_type === 'admin' && (
        <div className={Styles.tab_wrapper}>
          <NavLink to={`/admin_panel/${user?.user?.user_type}/parents`} className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
            <span>{'Clients'}</span>
          </NavLink>
        </div>
      )}
      {user?.user?.user_type === 'admin' && (
        <div className={Styles.tab_wrapper}>
          <NavLink to={`/admin_panel/${user?.user?.user_type}/advisors`} className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
            <span>{'Advisors'}</span>
          </NavLink>
        </div>
      )}
      {user?.user?.user_type === 'admin' && (
        <div className={Styles.tab_wrapper}>
          <NavLink to={`/admin_panel/${user?.user?.user_type}/meetings`} className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
            <span>{'Meetings'}</span>
          </NavLink>
        </div>
      )}
      {user?.user?.user_type === 'admin' && (
        <div className={Styles.tab_wrapper}>
          <NavLink to={`/admin_panel/admin/analytics`} className={({ isActive }) => (isActive ? `${Styles.active} ${Styles.chat}` : `${Styles.tab} ${Styles.chat}`)}>
            <span>{'Analytics'}</span>
          </NavLink>
        </div>
      )}
      {user.user.user_type === 'advisor' && (
        <div className={Styles.tab_wrapper}>
          <NavLink to={`/admin_panel/${user?.user?.user_type}/applications`} className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
            <span>{'Applications'}</span>
          </NavLink>
        </div>
      )}
      <div className={Styles.tab_wrapper}>
        <NavLink to={`/admin_panel/${user?.user?.user_type}/chat`} className={({ isActive }) => (isActive ? `${Styles.active} ${Styles.chat}` : `${Styles.tab} ${Styles.chat}`)}>
          <span>{'Chats'}</span>
          {isIconShow && <EnvelopIcon />}
        </NavLink>
      </div>
    </div>
  )
}
