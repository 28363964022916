import { useEffect, useState } from 'react'
import { useGetTherapistPerformanceQuery } from './service'
import { CenterColumn, CenterRow, Text } from 'components/Core'
import { DateRangePopover } from './DatePopover'
import { RangeKeyDict } from 'react-date-range'
import { IconButton, Tooltip } from '@mui/material'
import { usePagination } from 'hooks/usePagination'
import { QuestionMark } from '@mui/icons-material'
import { MuiTable } from 'components/MuiTable/MuiTable'
import { currencyFormatter } from 'utils/utils'

export const TherapistPerformance = () => {
  const { page, setTotalItems, goToPage, totalPages, pageSize, changePageSize } = usePagination({
    initialPageSize: 10,
    initialPage: 0,
  })
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const { data, isFetching } = useGetTherapistPerformanceQuery({
    end_date: endDate?.toISOString(),
    start_date: startDate?.toISOString(),
    page: page + 1,
    page_size: pageSize,
  })
  console.log(pageSize)
  const handleSelect = (ranges: RangeKeyDict) => {
    goToPage(0)
    setStartDate(ranges.selection.startDate!)
    setEndDate(ranges.selection.endDate!)
  }
  useEffect(() => {
    if (data?.count) setTotalItems(data?.count)
  }, [data?.count])
  return (
    <CenterColumn gap="20px" p={2} pt={20}>
      <CenterRow width="100%" justifyContent="space-between">
        <Text variant="heading2">Therapist Performance</Text>
        <DateRangePopover
          handleClear={() => {
            goToPage(0)
            setStartDate(undefined)
            setEndDate(undefined)
          }}
          endDate={endDate}
          startDate={startDate}
          handelSelect={handleSelect}
        />
      </CenterRow>
      <CenterRow bg="white" p={3} borderRadius={1} width="100%" gap="30px">
        <CenterRow gap="2px">
          <Text width="auto" variant="heading4">
            Overall totals :
          </Text>
          {/* <Tooltip title="Total sessions and  revenue for all therapists of all time, can be affected by the date range selected, if date range is not selected it will represent the all time session and revenue made by all therapist.">
            <IconButton size="small">
              <QuestionMark fontSize="small" />
            </IconButton>
          </Tooltip> */}
          {/* <Text width="auto" variant="heading4">:</Text> */}
        </CenterRow>
        <CenterRow gap={10} borderRight={1} pr={3}>
          {/* Total session */}
          <Text fontWeight="bold">Total non paid sessions:</Text>
          <Text>{data?.totals.non_paid_sessions}</Text>
        </CenterRow>
        <CenterRow gap={10} borderRight={1} pr={3}>
          {/* Total session */}
          <Text fontWeight="bold">Total paid session:</Text>
          <Text>{data?.totals.paid_sessions}</Text>
        </CenterRow>
        <CenterRow gap={10} borderRight={1} pr={3}>
          {/* Total session */}
          <Text fontWeight="bold">Total Sessions:</Text>
          <Text>{data?.totals.total_sessions}</Text>
        </CenterRow>
        <CenterRow gap={10}>
          {/* Total session */}
          <Text fontWeight="bold">Total Revenue:</Text>
          <Text>
            {currencyFormatter({
              amount: data?.totals.total_revenue,
            })}
          </Text>
        </CenterRow>
      </CenterRow>
      <MuiTable therapistView data={data} isFetching={isFetching} changePageSize={changePageSize} pageSize={pageSize} goToPage={goToPage} page={page} />
    </CenterColumn>
  )
}
