// Core
import { bool, func, number, object, shape, string } from 'prop-types'
import { useEffect, useState } from 'react'
// Instruments
import { Controller } from 'react-hook-form'
// Styles
import Styles from './styles.module.scss'
// Images
import errorIcon from '../../../theme/assets/icons/error.svg'

export const TextAreaField = (props) => {
  if (!props.name || !props.control) return null

  const [defValue, setDefValue] = useState(props?.defaultValue)
  const [newValue, setNewValue] = useState('')

  const [charactersAmmount, setCharactersAmmount] = useState(0)

  useEffect(() => {
    if (newValue) {
      setDefValue('')
    }
  }, [newValue])

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => {
        setCharactersAmmount(field?.value?.length ?? 0)
        return (
          <div className={`${Styles.text_wrapper} ${props?.class}`}>
            <div className={`${Styles.label_wrapper} ${props?.label_wrapper}`}>
              <label>
                {props.label}
                <span>{props?.requiredIcon}</span>
              </label>
            </div>
            <textarea
              {...field}
              value={field?.value || props.value || defValue || ''}
              autoFocus={props?.autoFocus}
              className={props?.inputClass}
              placeholder={props?.placeholder}
              disabled={props?.disabled}
              maxLength={props?.maxlength}
              onChange={(e) => {
                field?.onChange(e)
                setCharactersAmmount(e.target.value.length)
                setNewValue(e.target.value)
                if (props?.customOnChange) props?.customOnChange(e?.target?.value)
              }}
              onBlur={() => {
                if (props?.onBlur) props?.onBlur
              }}
              {...props.register}
            />
            {props?.maxlength && (
              <p style={{ direction: props?.language === 'ar' ? 'ltr' : 'rtl' }}>
                {charactersAmmount}/{props?.maxlength}
              </p>
            )}
            {props?.error && (
              <div className={`${Styles.error_wraper} ${props.error_wraper}`}>
                {props?.error ? <img className={Styles.error_icon} src={errorIcon} alt={'error'} /> : null}
                {props?.error && <div className={`${Styles.error} ${props.classError}`}>{props?.error ? <span>{props.error?.message || 'error'}</span> : null}</div>}
              </div>
            )}
          </div>
        )
      }}
    />
  )
}

TextAreaField.defaultProps = {
  type: 'text',
}

TextAreaField.propTypes = {
  name: string,
  control: object,
  label: string,
  class: string,
  requiredIcon: string,
  autoFocus: bool,
  onChange: func,
  inputClass: string,
  placeholder: string,
  disabled: bool,
  maxlength: number,
  onBlur: func,
  register: object,
  classError: string,
  error: shape({
    message: string,
  }),
}
