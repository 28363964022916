import { Autocomplete, Checkbox } from '@mui/material'
import { Button, CenterRow, Flex, Text } from 'components/Core'
import { TextField as MuiTextField } from '@mui/material'
import { ParentTaskStatusDropDownList } from 'constants/index'
import { useForm, useWatch } from 'react-hook-form'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { useEffect, useMemo } from 'react'

import { ParentTask } from './slice/types'
import { useGetObjectivesQuery } from '../TreatmentPlan/slice/services'
import { useCreateOrUpdateParentTaskMutation, useDeleteParentTaskMutation } from './slice/services'
import { ConfirmationModal } from 'components/shared/ConfimationModal'
import { useDisclosure } from 'hooks/useDisclosure'
import { yupResolver } from '@hookform/resolvers/yup'
import { parentTaskFormValidator } from './validation'

interface FormState extends ParentTask {
  checked: boolean
}
type Props = {
  profile_id: number
  task: ParentTask
  closeForm: () => void
}
export const ParentTaskForm = ({ profile_id, closeForm, task }: Props) => {
  const deleteModalController = useDisclosure()
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<FormState>({
    mode: 'all',
    defaultValues: {
      ...task,
      checked: task.objective ? true : false,
    },
    resolver: yupResolver(parentTaskFormValidator),
  })

  /**@Effects */
  const checked = useWatch({
    control,
    name: 'checked',
  })
  useEffect(() => {
    if (!checked) {
      setValue('objective', undefined)
    }
  }, [checked])
  const { data: objectives, isLoading } = useGetObjectivesQuery(profile_id, { skip: !profile_id })
  const objectiveOptions = useMemo(() => {
    if (!objectives) return []
    return objectives?.map((item) => {
      return {
        ...item,
        goalTitle: item.goal?.title,
      }
    })
  }, [objectives])
  const isUpdate = useMemo(() => !!task.id, [task.id])
  /**@END */

  const [createTask, { isLoading: creatingTask }] = useCreateOrUpdateParentTaskMutation()
  const onSubmit = (data: FormState) => {
    createTask({
      data: {
        profile_id,
        objective_id: data?.objective?.id as number,
        title: data.title,
        description: data.description,
        id: task.id,
        status: data.status,
      },
      isUpdate,
      resetState() {
        reset()
        closeForm()
      },
    })
  }
  const watchObjective = useWatch({
    control,
    name: 'objective',
  })
  // const watchStatus = useWatch({
  //   control,
  //   name: 'status',
  // })
  const [deleteTask, { isLoading: deletingTask }] = useDeleteParentTaskMutation()

  return (
    <Flex gap={15} width={['100%', '100%', '100%', '80%', '70%', '70%', '50%']} py={3} flexDirection="column">
      <Text variant="heading4">{isUpdate ? 'Update Task' : 'Add Task'}</Text>
      <MuiTextField {...register('title')} required placeholder="Add title" error={!!errors.title} helperText={errors.title?.message} />
      <MuiTextField
        maxRows={4}
        multiline
        {...register('description')}
        required
        placeholder="Add description"
        error={!!errors.description}
        helperText={errors.description?.message}
      />

      {/* {isUpdate && (
        <Autocomplete
          loading={isLoading}
          options={ParentTaskStatusDropDownList}
          defaultValue={{ value: task.status }}
          getOptionLabel={(option) => option?.value ?? ''}
          sx={{ width: '100%' }}
          renderInput={(params) => <MuiTextField {...params} label="Status" />}
          onChange={(_, value) => {
            setValue('status', value?.value!)
          }}
          value={{ value: watchStatus }}
        />
      )} */}
      <CenterRow>
        <Checkbox checked={checked} {...register('checked')} /> <Text fontSize="18px">Related to objective</Text>
      </CenterRow>
      {checked ? (
        <Autocomplete
          loading={isLoading}
          // @ts-ignore
          options={objectiveOptions?.sort((a, b) => -b?.goalTitle?.localeCompare(a?.goalTitle as string))}
          defaultValue={task.objective}
          groupBy={(option) => option?.goal?.title ?? ('' as string)}
          getOptionLabel={(option) => option?.title ?? ''}
          sx={{ width: '100%' }}
          renderInput={(params) => <MuiTextField {...params} label="Objective" error={!!errors.objective} helperText={errors.objective?.message} />}
          onChange={(_, value) => {
            setValue('objective', value!)
          }}
          value={watchObjective ?? {}}
        />
      ) : null}

      <CenterRow gap={10} width="100%" justifyContent="flex-end">
        {isUpdate && (
          <Button variant="danger" onClick={deleteModalController.onOpen}>
            {deletingTask ? <Spinner width="25px" /> : 'Delete'}
          </Button>
        )}
        <Button onClick={closeForm} variant="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>{creatingTask ? <Spinner width="25px" /> : isUpdate ? 'Update' : 'Create'}</Button>
      </CenterRow>
      <ConfirmationModal
        message="Are you sure you want to delete this task?"
        onConfirm={() =>
          deleteTask({
            taskId: task.id as number,
            onSuccess() {
              closeForm()
              deleteModalController.onClose()
            },
          })
        }
        title={`Delete Task`}
        open={deleteModalController.open}
        onClose={deleteModalController.onClose}
      />
    </Flex>
  )
}
