//Core
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
//Styles
import Styles from './Styles.module.scss'
// Data
import {
  mandatory_questions_parent_onboarding,
  parent_onboarding_report_family_dynamics,
  parent_onboarding_report_parental_wellbeing,
  parent_onboarding_report_questionnaire,
} from '../../../../data'
import { MAIN_STATEMENTS_ANSWERS, ONBOARDING_STEP, PARENT_ONBOARDING_COOKIES_NAME, SKIPPED_VALUE } from '../../../../data/enums'
// Icons
import { ReactComponent as ArrowLeftIcon } from '../../../../theme/assets/icons/arrow_left_icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../theme/assets/icons/arrow_right_icon.svg'
import { ReactComponent as ClockImage } from '../../../../theme/assets/images/clock_image.svg'
// Utils
import { deleteCookie, deleteLocalStorage, formatDateToYYYYMMDD, getLocalStorage, returnLanguagesArray, setLocalStorage } from '../../../../utils'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'
import { returnParagraphForReport, returnParagraphForNoReport } from './locale/locale.functions'
// Form
import { useForm } from 'react-hook-form'
// Components
import { TextAreaField } from '../../../GeneralComponents'
import { useCreateNewProfileMutation } from './service'
import { selectUser } from 'defaultLayout/slice/selectors'
import { useSelector } from 'react-redux'
import { routes } from 'routes'
import { CircularProgress } from '@mui/material'

export const ParentOnboardingReport = ({ isVariantA, setOnboardingStep, setAddChildStep, isAccountExists }) => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const navigate = useNavigate()

  const [childName, setChildName] = useState('')
  const [childInformation, setChildInformarion] = useState({})
  const [questionnaire, setQuestionnaire] = useState([])
  const [familyDynamics, setFamilyDynamics] = useState([])
  const [parentalWellbeing, setParentalWellbeing] = useState([])

  const [familyDynamicsReport, setFamilyDynamicsReport] = useState({})
  const [parentalWellbeingReport, setParentalWellbeingReport] = useState({})

  const [reportLoading, setReportLoading] = useState(false)
  const [isReportExist, setIsReportExist] = useState(true)

  const [selectedLanguage, setSelectedLanguage] = useState(searchParams.get('lng'))

  const [addNewChildProfile, { isSuccess, isLoading, data }] = useCreateNewProfileMutation()

  useEffect(() => {
    if (isSuccess) {
      deleteLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING)
      deleteLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.QUESTIONNAIRE)
      deleteLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.FAMILY_DYNAMICS)
      deleteLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENTAL_WELLBEING)
      for (let i = 0; i < mandatory_questions_parent_onboarding.length; i++) {
        deleteLocalStorage(`${PARENT_ONBOARDING_COOKIES_NAME.QUESTIONNAIRE}_${i}`)
      }
      localStorage.setItem('lastSelectedProfile', data.id)
      navigate(routes.parentTherapy)
    }
  }, [isSuccess])

  const { handleSubmit, control, setValue } = useForm({
    mode: 'all',
    defaultValues: {
      additional_info: '',
    },
  })

  useEffect(() => {
    setSelectedLanguage(searchParams.get('lng'))
    i18n.changeLanguage(searchParams.get('lng'))
  }, [searchParams])

  useEffect(() => {
    i18n.addResourceBundle('en', 'report', en)
    i18n.addResourceBundle('ar', 'report', ar)
  }, [])

  useEffect(() => {
    const childInformation = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
    setChildInformarion(childInformation)
    setChildName(childInformation.child_info?.child_name)
    setReportLoading(childInformation.report_loading)
    setValue('additional_info', childInformation?.additional_info || '')

    setTimeout(() => {
      setReportLoading(false)
      childInformation.report_loading = false
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(childInformation))
    }, 3000)

    const questionnaire = JSON.parse(localStorage.getItem('questionnaire') ?? '[]')
    setQuestionnaire(questionnaire)

    setFamilyDynamics(JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.FAMILY_DYNAMICS)))
    setParentalWellbeing(JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENTAL_WELLBEING)))
  }, [])

  useEffect(() => {
    setFamilyDynamicsReport(parent_onboarding_report_family_dynamics(childName, familyDynamics))
    setParentalWellbeingReport(parent_onboarding_report_parental_wellbeing(childName, parentalWellbeing))
  }, [familyDynamics, childName, parentalWellbeing])

  useEffect(() => {
    const sectionWithYesAnswer = questionnaire?.find((section) => section?.main_statement_answer === MAIN_STATEMENTS_ANSWERS?.[0])
    if (!sectionWithYesAnswer && !parentalWellbeingReport && !familyDynamicsReport) {
      setIsReportExist(false)
    } else {
      setIsReportExist(true)
    }
  }, [familyDynamicsReport, parentalWellbeingReport, questionnaire])

  const handleGoBack = () => {
    const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
    const theLastAnsweredQuestionParentalWellbeing = parentalWellbeing?.find((question) => question?.answer !== SKIPPED_VALUE)
    if (theLastAnsweredQuestionParentalWellbeing) {
      mainLocalStorage.parental_wellbeing_read_view = theLastAnsweredQuestionParentalWellbeing?.id
      setOnboardingStep(isVariantA ? ONBOARDING_STEP.QUESTIONNAIRE : ONBOARDING_STEP.PARENTAL_WELLBEING)
    } else {
      mainLocalStorage.parental_wellbeing_read_view = null
      setOnboardingStep(isVariantA ? ONBOARDING_STEP.QUESTIONNAIRE : ONBOARDING_STEP.PARENTAL_WELLBEING_DISCLAIMER)
    }
    setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
  }

  const handleGoToAccountCreating = (data) => {
    const concerns = childInformation?.child_info?.concerns?.map((concern) => concern?.value)
    const behavior = childInformation?.child_info?.behavior?.map((concern) => concern?.value)
    const languages = childInformation?.child_info?.languages?.map((language) => returnLanguagesArray(language))
    if (isAccountExists) {
      const finalObject = {
        payload: {
          child_info: {
            ...childInformation?.child_info,
            birth_date: formatDateToYYYYMMDD(childInformation?.child_info?.birth_date),
            behavior,
            concerns,
            languages,
          },
          questionnaire,
          family_dynamics: familyDynamics ?? [],
          parental_wellbeing: parentalWellbeing ?? [],
        },
      }
      addNewChildProfile({ payload: finalObject.payload, token: user?.token })
    } else {
      if (isVariantA) {
        setAddChildStep(1)
      }
      setOnboardingStep(isVariantA ? ONBOARDING_STEP.ADD_CHILD : ONBOARDING_STEP.FORM_REGISTRATION)
      const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
      mainLocalStorage.state = isVariantA ? ONBOARDING_STEP.ADD_CHILD : ONBOARDING_STEP.FORM_REGISTRATION
      mainLocalStorage.additional_info = data?.additional_info || ''
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
    }
  }
  return (
    <div className={Styles.main_container}>
      {!reportLoading && (
        <>
          <div className={Styles.main_container__header}>
            <h3>{t('report:header')}</h3>
            <div onClick={handleSubmit(handleGoToAccountCreating)} className={Styles.main_container__header__btn}>
              {isLoading && isAccountExists ? <CircularProgress sx={{ color: 'white' }} size={25} /> : t('report:call_to_action')}
            </div>
            <div className={Styles.main_container__header__disclamer}>
              <span>{t('report:disclaimer:header')}</span> {t('report:disclaimer:paragraph')}
            </div>
            {isReportExist && (
              <h6 className="ClarityMask" style={{ direction: selectedLanguage === 'en' ? 'ltr' : 'rtl' }}>
                {returnParagraphForReport(childName)?.[selectedLanguage]}
              </h6>
            )}
          </div>
          {isReportExist && (
            <div className={`${Styles.main_container__content} ClarityMask`}>
              {questionnaire?.map((section) => {
                const sectionSelector = `${PARENT_ONBOARDING_COOKIES_NAME.QUESTIONNAIRE}_${section?.id}`
                if (section?.main_statement_answer === MAIN_STATEMENTS_ANSWERS?.[0]) {
                  return (
                    <div style={{ direction: selectedLanguage === 'en' ? 'ltr' : 'rtl' }} className={Styles.main_container__content__item} key={section?.id}>
                      <h6>{parent_onboarding_report_questionnaire(childName)[sectionSelector]?.section_name?.[selectedLanguage]}</h6>
                      <p>{parent_onboarding_report_questionnaire(childName)[sectionSelector]?.feedback?.[selectedLanguage]}</p>
                    </div>
                  )
                }
              })}
              {familyDynamicsReport?.section_name && (
                <div style={{ direction: selectedLanguage === 'en' ? 'ltr' : 'rtl' }} className={Styles.main_container__content__item}>
                  <h6>{familyDynamicsReport?.section_name?.[selectedLanguage]}</h6>
                  <p>{familyDynamicsReport?.feedback?.[selectedLanguage]}</p>
                </div>
              )}
              {parentalWellbeingReport?.section_name && (
                <div style={{ direction: selectedLanguage === 'en' ? 'ltr' : 'rtl' }} className={Styles.main_container__content__item}>
                  <h6>{parentalWellbeingReport?.section_name?.[selectedLanguage]}</h6>
                  <p>{parentalWellbeingReport?.feedback?.[selectedLanguage]}</p>
                </div>
              )}
            </div>
          )}
          {!isReportExist && (
            <div className={Styles.main_container__content}>
              <h5 style={{ direction: selectedLanguage === 'en' ? 'ltr' : 'rtl' }}>{t('report:no_report:header')}</h5>
              <p className="ClarityMask" style={{ direction: selectedLanguage === 'en' ? 'ltr' : 'rtl' }}>
                {returnParagraphForNoReport(childName)?.[selectedLanguage]}
              </p>
              <span>{t('report:no_report:call_to_action')}</span>
            </div>
          )}
          <div className={Styles.main_container__content}>
            <TextAreaField
              maxlength={2000}
              class={selectedLanguage === 'ar' && Styles.ar_direction}
              label_wrapper={Styles.main_container__content__form__label}
              name="additional_info"
              control={control}
              label={t('report:textarea:label')}
              inputClass={Styles.main_container__content__form}
              placeholder={t('report:textarea:placeholder')}
              language={selectedLanguage}
            />
            <div style={{ width: '100%' }} onClick={handleSubmit(handleGoToAccountCreating)} className={Styles.main_container__header__btn}>
              {isLoading && isAccountExists ? <CircularProgress sx={{ color: 'white' }} size={25} /> : t('report:call_to_action')}
            </div>
          </div>
          <div style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }} onClick={handleGoBack} className={Styles.main_container__footer}>
            {selectedLanguage === 'en' ? <ArrowLeftIcon /> : <ArrowRightIcon />} {t('report:footer:back')}
          </div>
        </>
      )}
      {reportLoading && (
        <div className={Styles.loading}>
          <ClockImage />
          <h5>{t('report:analyzing')}</h5>
        </div>
      )}
    </div>
  )
}
