import { LineChart } from '@mui/x-charts/LineChart'
import type { Progress } from './slice/types'
import { useMemo } from 'react'
import { dateFormatter } from 'utils/utils'
import { theme } from 'utils/theme'
import type { TFunction } from 'react-i18next'

type Props = {
  baselinePercentage: number | undefined
  targetPercentage: number | undefined
  progresses: Progress[] | undefined
  objectiveCreatedAt: string
  t: TFunction<'translation', undefined>
  isLoadingPlans: boolean
}

export function QuantitativeProgressChart({ isLoadingPlans, baselinePercentage, progresses, targetPercentage, objectiveCreatedAt, t }: Props) {
  const dataset = useMemo(() => {
    return (
      progresses
        ?.filter((progress) => progress.quantitative_value != null)
        ?.map((progress) => ({
          x: dateFormatter({ date: progress.session.date, formatType: 'PP' }),
          y: Number(progress.quantitative_value),
          baseline: baselinePercentage,
          target: targetPercentage,
        })) || []
    )
  }, [progresses, baselinePercentage, targetPercentage])

  return (
    <LineChart
      sx={{ direction: 'ltr' }}
      dataset={[
        {
          x: dateFormatter({ date: objectiveCreatedAt, formatType: 'PP' }) as string,
          y: Number(baselinePercentage),
          baseline: baselinePercentage,
          target: targetPercentage,
        },
        ...dataset,
      ]}
      xAxis={[
        {
          dataKey: 'x',
          scaleType: 'point',
        },
      ]}
      yAxis={[
        {
          min: 0,
          max: 100,
        },
      ]}
      series={[
        { dataKey: 'baseline', label: t('treatmentPlanPlusTask:baseline'), color: theme.colors.primary[800], showMark: false },
        { dataKey: 'y', label: t('treatmentPlanPlusTask:currentProgress'), color: theme.colors.stateSuccess },
        { dataKey: 'target', label: t('treatmentPlanPlusTask:target'), color: theme.colors.typePurpure[300], showMark: false },
      ]}
      height={300}
      slotProps={{
        legend: {
          direction: 'row',
          position: { vertical: 'top', horizontal: 'middle' },
          padding: 0,
        },
      }}
    />
  )
}
