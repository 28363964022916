import { Button, CenterRow, Chip, type ChipTypes, Flex, Text } from 'components/Core'
import type { ParentTask } from './slice/types'
import { CommentOutlined, Edit, Visibility } from '@mui/icons-material'
import { Card, CardActions, CardContent, CardHeader, IconButton, TextField } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { ParentTaskStatus, parentTaskStatusMap } from 'constants/index'
import { dateFormatter } from 'utils/utils'
import { useCommentParentTaskMutation, useCreateOrUpdateParentTaskMutation } from './slice/services'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { useNavigate } from 'react-router-dom'
import { ChipWithMenu } from 'components/shared/ChipWithMenu'
import { TFN } from 'utils/types/types'

type Props = {
  task: ParentTask
  setEditTask: (task: ParentTask) => void
  readOnly: boolean
  canComment?: boolean
  profile_id: number
  canChangeStatus?: boolean
  onClick: () => void
  t: TFN
  setAutoFocus?: Dispatch<SetStateAction<boolean>>
}
export const ParentTaskList = ({ task, t, setEditTask, onClick, setAutoFocus, readOnly, canComment, profile_id, canChangeStatus = false }: Props) => {
  const navigate = useNavigate()
  const chipType = useMemo<ChipTypes>(() => {
    if (task.status === ParentTaskStatus.DONE) {
      return 'success'
    } else if (task.status === ParentTaskStatus.TODO) {
      return 'info'
    } else {
      return 'warning'
    }
  }, [task.status])

  const parentTaskOptions = useMemo(
    () => [
      { value: ParentTaskStatus.DONE, label: t(`treatmentPlanPlusTask:${parentTaskStatusMap[ParentTaskStatus.DONE]}`) },
      { value: ParentTaskStatus.INCOMPLETE, label: t(`treatmentPlanPlusTask:${parentTaskStatusMap[ParentTaskStatus.INCOMPLETE]}`) },
    ],
    [],
  )
  const [updateTask, { isLoading }] = useCreateOrUpdateParentTaskMutation()

  return (
    <Card
      sx={{
        height: '100%',
        maxHeight: '360px',
        cursor: 'pointer',
      }}
      onClick={() => {
        if (onClick) {
          onClick()
        } else {
          navigate(`/parent-task/${task.id}`)
        }
      }}
    >
      <Flex height="100%" flexDirection="column" alignItems="space-between" gap={1}>
        <Flex flexDirection="column" flexGrow={1}>
          <CardHeader
            title={task.title}
            subheader={dateFormatter({
              date: task.created_at,
              formatType: 'PP',
            })}
            action={
              task.status === ParentTaskStatus.TODO && !readOnly ? (
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation()
                    setEditTask(task)
                  }}
                >
                  <Edit />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    if (onClick) {
                      onClick()
                    } else {
                      navigate(`/parent-task/${task.id}`)
                    }
                  }}
                  color="primary"
                >
                  <Visibility />
                </IconButton>
              )
            }
          />
          <CardContent sx={{ py: 0 }}>
            <Text lineHeight={1.2} truncateLines={3} color="textGray" mb={1}>
              {task.description}
            </Text>
            {task.comment && (
              <Flex mt={10} flexDirection={['column']} gap="5px">
                <CenterRow>
                  <Text textDecoration="underline" fontWeight="bold" fontSize={13} color="textLightGray">
                    {canComment ? t('treatmentPlanPlusTask:comment') : 'Parent Comment'}
                  </Text>
                </CenterRow>
                <Text truncateLines={2} maxWidth="230px" fontSize={13} color="textGray">
                  {task.comment}
                </Text>
              </Flex>
            )}
            {task.objective && (
              <Flex mt={10} flexDirection={['column']} gap="5px">
                <CenterRow>
                  <Text textDecoration="underline" fontWeight="bold" fontSize={13} color="textLightGray">
                    {t('treatmentPlanPlusTask:objective')}
                  </Text>
                </CenterRow>
                <Text truncateLines={2} maxWidth="230px" fontSize={13} color="textGray">
                  {task.objective.title}
                </Text>
              </Flex>
            )}
          </CardContent>
        </Flex>
        <Flex flexDirection="column">
          <CardActions>
            <CenterRow justifyContent="space-between" width="100%">
              <CenterRow>
                <ChipWithMenu
                  type={chipType}
                  value={{ value: task.status, label: t(`treatmentPlanPlusTask:${parentTaskStatusMap[task.status]}`) }}
                  allowEdit={canChangeStatus && task.status === ParentTaskStatus.TODO}
                  options={parentTaskOptions}
                  isLoading={isLoading}
                  onChange={(value) => {
                    updateTask({
                      data: {
                        ...task,
                        status: value.value,
                        objective_id: task.objective?.id as number,
                        profile_id,
                      },
                      isUpdate: true,
                    })
                  }}
                />
                {task.status === ParentTaskStatus.DONE && (
                  <CenterRow gap="10px">
                    <Text color="textSecondary">{t('treatmentPlanPlusTask:on')}</Text>
                    <Text color="textSecondary">{dateFormatter({ date: task.done_at, formatType: 'PP' })}</Text>
                  </CenterRow>
                )}
                {task.status === ParentTaskStatus.INCOMPLETE && (
                  <CenterRow gap="10px">
                    <Text color="textSecondary">{t('treatmentPlanPlusTask:on')}</Text>
                    <Text color="textSecondary">{dateFormatter({ date: task.incomplete_at, formatType: 'PP' })}</Text>
                  </CenterRow>
                )}
              </CenterRow>
              {canComment ? (
                <IconButton
                  onClick={() => {
                    onClick?.()
                    setAutoFocus?.(true)
                  }}
                >
                  <CommentOutlined />
                </IconButton>
              ) : null}
            </CenterRow>
          </CardActions>
        </Flex>
      </Flex>
    </Card>
  )
}
