import { useState, useMemo } from 'react'

interface PaginationOptions {
  initialPage?: number
  initialPageSize?: number
  totalItems?: number
}

export const usePagination = (options: PaginationOptions = {}) => {
  const [page, setPage] = useState(options.initialPage ?? 1)
  const [pageSize, setPageSize] = useState(options.initialPageSize ?? 10)
  const [totalItems, setTotalItems] = useState(options.totalItems || 0)

  const totalPages = useMemo(() => Math.ceil(totalItems / pageSize), [totalItems, pageSize])
  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1)
    }
  }

  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const goToPage = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const changePageSize = (newPageSize: number) => {
    setPageSize(newPageSize)
    setPage(1)
  }

  const paginationRange = useMemo(() => {
    const start = (page - 1) * pageSize
    const end = Math.min(start + pageSize, totalItems)
    return { start, end }
  }, [page, pageSize, totalItems])

  return {
    page,
    pageSize,
    totalItems,
    totalPages,
    nextPage,
    previousPage,
    goToPage,
    changePageSize,
    setTotalItems,
    paginationRange,
    hasNextPage: page < totalPages,
    hasPreviousPage: page > 1,
  }
}
