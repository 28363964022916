import { arabicAlertMessages } from 'components/AlertMessage/locale/aabicAlertMessages'
import { englishAlertMessages } from 'components/AlertMessage/locale/englishAlertMessages'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const DefaultLayout = () => {
  const { i18n } = useTranslation()
  useEffect(() => {
    i18n.addResourceBundle('en', 'alertMessages', englishAlertMessages)
    i18n.addResourceBundle('ar', 'alertMessages', arabicAlertMessages)
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [window.location.pathname])
  return <></>
}
