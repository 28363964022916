import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/type'

const rootState = (state: RootState) => state

export const selectParentTaskState = createSelector([rootState], (state) => state.parentTask)

export const selectParentTask = createSelector([rootState], (state) => state.parentTask.parentTask)
export const selectParentTaskFormOpened = createSelector([selectParentTaskState], (state) => state.parentTaskFormOpened)
export const selectProfileId = createSelector([selectParentTaskState], (state) => state.profile_id)
export const selectFilters = createSelector([selectParentTaskState], (state) => state.filters)
