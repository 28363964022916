import { CenterColumn, CenterRow, Text } from 'components/Core'
import { useGetConversionsQuery } from './service'
import { Card, CardContent, CardHeader } from '@mui/material'
import { DateRangePicker, type RangeKeyDict } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { useState } from 'react'
import { useDisclosure } from 'hooks/useDisclosure'
import { DateRangePopover } from './DatePopover'
import { Spinner } from 'components/ChatSystem/Common/Spinner'

export const Conversions = () => {
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const { data, isError, isFetching } = useGetConversionsQuery({
    end_date: endDate?.toISOString(),
    start_date: startDate?.toISOString(),
  })
  const handleSelect = (ranges: RangeKeyDict) => {
    setStartDate(ranges.selection.startDate!)
    setEndDate(ranges.selection.endDate!)
  }
  return (
    <CenterColumn gap="10px" p={2} pt={20}>
      <CenterRow width="100%" justifyContent="space-between">
        <Text variant="heading2">Conversions</Text>
        <DateRangePopover
          handleClear={() => {
            setStartDate(undefined)
            setEndDate(undefined)
          }}
          endDate={endDate}
          startDate={startDate}
          handelSelect={handleSelect}
        />
      </CenterRow>
      {!isFetching ? (
        <CenterRow width="100%" gap="30px">
          <Card
            sx={{
              maxWidth: '400px',
            }}
          >
            <CardHeader
              title="# New Sign Ups"
              // subheader="New sign up conversion Rate"
            />
            <CardContent>
              <Text fontSize={['20px', 30, 35, 40]} fontWeight="bold">
                {formatNumber(data?.new_signups)}
              </Text>
            </CardContent>
          </Card>
          <Card
            sx={{
              maxWidth: '400px',
            }}
          >
            <CardHeader
              title="# Advisory Calls"
              // subheader="Advisory call conversion rate"
            />
            <CardContent>
              <Text fontSize={['20px', 30, 35, 40]} fontWeight="bold">
                {formatNumber(data?.advisory_calls)}
              </Text>
            </CardContent>
          </Card>
          <Card
            sx={{
              maxWidth: '400px',
            }}
          >
            <CardHeader
              title="# Discovery Call"
              // subheader="Discovery call conversion rate"
            />
            <CardContent>
              <Text fontSize={['20px', 30, 35, 40]} fontWeight="bold">
                {formatNumber(data?.discovery_calls)}
              </Text>
            </CardContent>
          </Card>
          <Card
            sx={{
              maxWidth: '400px',
            }}
          >
            <CardHeader
              title="# Therapy Call"
              // subheader="Therapy call conversion rate"
            />
            <CardContent>
              <Text fontSize={['20px', 30, 35, 40]} fontWeight="bold">
                {formatNumber(data?.paid_consultations)}
              </Text>
            </CardContent>
          </Card>
        </CenterRow>
      ) : (
        <Spinner />
      )}
    </CenterColumn>
  )
}

const formatNumber = (number: number | undefined) => {
  if (!number) return 0
  return new Intl.NumberFormat('en-IN').format(number)
}
