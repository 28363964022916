import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { CenterRow, Chip, type ChipTypes } from 'components/Core'
import { globalChipTypeMapper } from 'constants/index'
import { useDisclosure } from 'hooks/useDisclosure'
import { type SyntheticEvent, useEffect, useRef } from 'react'

type Item = { label: string; value: string }
type Props = {
  options: Array<Item>
  onChange: (value: Item) => void
  type?: ChipTypes
  value?: Item
  isLoading?: boolean
  allowEdit?: boolean
}
export const ChipWithMenu = ({ options, onChange, type, value, isLoading, allowEdit }: Props) => {
  const { open, onOpen, onClose, toggle } = useDisclosure()
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleClose = (event: Event | SyntheticEvent) => {
    event?.stopPropagation()
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    onClose()
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    event?.stopPropagation()
    if (event.key === 'Tab') {
      event.preventDefault()
      onOpen()
    } else if (event.key === 'Escape') {
      onOpen()
    }
  }
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])
  return allowEdit ? (
    <>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          e.stopPropagation()
          toggle()
        }}
        variant="text"
        sx={{
          textTransform: 'capitalize',
          p: 0,
        }}
      >
        {isLoading ? (
          <Spinner width="40px" />
        ) : (
          <CenterRow>
            <Chip type={type} value={value?.label} rightAdornment={open ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" />} />
          </CenterRow>
        )}
      </Button>
      <Popper sx={{ zIndex: 100 }} open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
                  {options.map((item) => (
                    <MenuItem
                      key={item.value}
                      onClick={(e) => {
                        e.stopPropagation()
                        onChange(item)
                        onClose()
                      }}
                    >
                      <Chip borderRadius={'10px'} width="100%" value={item.label} type={globalChipTypeMapper[item.value as keyof typeof globalChipTypeMapper] as ChipTypes} />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  ) : (
    <Chip type={type} value={value?.label} />
  )
}
