import { TextField, type TextFieldProps } from '@mui/material'
import type { ComponentProps } from 'react'
import { CenterColumn, CenterRow } from '../Box/Box'
import { Text } from '../Text/Text'
import { theme } from 'utils/theme'
import type { TextProps } from '../Text/types'

interface Props extends ComponentProps<typeof TextField> {
  maxLength?: number
  labelStyle?: TextProps
  showCharacterCounter?: boolean
}
export const MuiTextField = ({ maxLength, label, required, showCharacterCounter, sx, ...rest }: Props) => {
  return (
    <CenterColumn gap={14}>
      <CenterRow>
        <Text color="mainText" fontSize={14}>
          {label}
        </Text>
        {required && (
          <Text fontSize={14} color="red">
            *
          </Text>
        )}
      </CenterRow>
      <TextField
        inputProps={{
          maxLength,
        }}
        multiline
        maxRows={4}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: rest.disabled ? 'transparent' : theme.colors.white,
          },
          borderColor: theme.colors.black[300],
          ':hover': {
            borderColor: 'none',
          },
          ...sx,
        }}
        {...rest}
      />
      {showCharacterCounter && (
        <CenterRow justifyContent="flex-end">
          <Text color="textGray" fontSize="16px">
            {rest.value?.toString().length ?? 0}/{maxLength}
          </Text>
        </CenterRow>
      )}
    </CenterColumn>
  )
}
