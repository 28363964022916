import { Button, CenterColumn, CenterRow, Chip, type ChipTypes, Container, Flex, Text } from 'components/Core'
import { useNavigate, useParams } from 'react-router-dom'
import { useCommentParentTaskMutation, useCreateOrUpdateParentTaskMutation, useGetOneParentTaskQuery } from './slice/services'
import { ParentTaskStatus, parentTaskStatusMap } from 'constants/index'
import { type Dispatch, type SetStateAction, useEffect, useMemo, useState } from 'react'
import { IconButton, TextField } from '@mui/material'
import { ArrowRightAlt, Edit, KeyboardBackspace } from '@mui/icons-material'
import { dateFormatter } from 'utils/utils'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { TFN } from 'utils/types/types'
import { useUserTypeCheck } from 'hooks/useUserTypeCheck'
import { useParentTaskSlice } from './slice'
import { ChipWithMenu } from 'components/shared/ChipWithMenu'

type Props = {
  parentTaskId?: number
  t: TFN
  onGoBack?: () => void
  isArabic?: boolean
  canComment?: boolean
  autoFocus?: boolean
  setAutoFocus?: Dispatch<SetStateAction<boolean>>
  canChangeStatus: boolean
  profile_id: number
}
export const ParentTaskDetail = ({ parentTaskId, isArabic, canComment, onGoBack, setAutoFocus, autoFocus, t, canChangeStatus, profile_id }: Props) => {
  const [commentValue, setCommentValue] = useState('')
  const [updateTask, { isLoading: updatingTask }] = useCreateOrUpdateParentTaskMutation()
  const [commentFormOpened, openCommentForm] = useState(false)
  const { setEditParentTaskValue } = useParentTaskSlice()
  const { parentTaskIdParam } = useParams()
  const taskId = useMemo(() => parentTaskId ?? parentTaskIdParam, [parentTaskId, parentTaskIdParam])
  const { data: task, isFetching: gettingDetail } = useGetOneParentTaskQuery({ id: Number(taskId) }, { skip: !taskId })
  const chipType = useMemo<ChipTypes>(() => {
    if (task?.status === ParentTaskStatus.DONE) {
      return 'success'
    } else if (task?.status === ParentTaskStatus.TODO) {
      return 'info'
    } else {
      return 'warning'
    }
  }, [task?.status])
  const navigate = useNavigate()
  const [comment, { isLoading }] = useCommentParentTaskMutation()
  useEffect(() => {
    if (task?.comment) {
      setCommentValue(task?.comment)
    }
  }, [task?.comment])
  const { isUserTherapist } = useUserTypeCheck()

  useEffect(() => {
    if (autoFocus) {
      openCommentForm(true)
    }
  }, [autoFocus])

  const parentTaskOptions = useMemo(
    () => [
      { value: ParentTaskStatus.DONE, label: t(`treatmentPlanPlusTask:${parentTaskStatusMap[ParentTaskStatus.DONE]}`) },
      { value: ParentTaskStatus.INCOMPLETE, label: t(`treatmentPlanPlusTask:${parentTaskStatusMap[ParentTaskStatus.INCOMPLETE]}`) },
    ],
    [],
  )

  return (
    <Container pt={2} justifyContent={'flex-start'} minHeight={'100vh'} flexDirection={['column']} gap={20}>
      <CenterRow boxShadow="normal" borderRadius="10px" gap="10px" p={3} width={['100%']} bg="white">
        <IconButton
          onClick={() => {
            if (onGoBack) {
              onGoBack()
              setAutoFocus?.(false)
            } else {
              navigate(-1)
            }
          }}
        >
          {isArabic ? <ArrowRightAlt /> : <KeyboardBackspace />}
        </IconButton>
        <Text variant="heading4">{t('treatmentPlanPlusTask:taskDetail')}</Text>
      </CenterRow>
      <Flex justifyContent={gettingDetail ? 'center' : 'flex-start'} boxShadow="normal" borderRadius="10px" width={['100%']} p={3} bg="white">
        {/* <Text variant="heading4">Parent Task Detail</Text> */}
        {gettingDetail ? (
          <Spinner />
        ) : (
          <CenterColumn width="100%" gap={30}>
            <CenterRow position="relative" width="100%" justifyContent={['space-between']}>
              <CenterColumn>
                <Text variant="light">{t('treatmentPlanPlusTask:status')}</Text>
                <CenterRow>
                  {task?.status ? (
                    <ChipWithMenu
                      type={chipType}
                      value={{ value: task?.status as string, label: t(`treatmentPlanPlusTask:${parentTaskStatusMap[task?.status!]}`) }}
                      allowEdit={canChangeStatus && task?.status === ParentTaskStatus.TODO}
                      options={parentTaskOptions}
                      isLoading={updatingTask || gettingDetail}
                      onChange={(value) => {
                        updateTask({
                          data: {
                            ...task,
                            status: value.value,
                            objective_id: task?.objective?.id as number,
                            profile_id,
                          },
                          isUpdate: true,
                        })
                      }}
                    />
                  ) : (
                    '--'
                  )}
                  {task?.status === ParentTaskStatus.DONE && (
                    <CenterRow gap="10px">
                      <Text color="textSecondary">{t('treatmentPlanPlusTask:on')}</Text>
                      <Text color="textSecondary">{dateFormatter({ date: task.done_at, formatType: 'PP' })}</Text>
                    </CenterRow>
                  )}
                  {task?.status === ParentTaskStatus.INCOMPLETE && (
                    <CenterRow gap="10px">
                      <Text color="textSecondary">{t('treatmentPlanPlusTask:on')}</Text>
                      <Text color="textSecondary">{dateFormatter({ date: task.incomplete_at, formatType: 'PP' })}</Text>
                    </CenterRow>
                  )}
                </CenterRow>
              </CenterColumn>
              {isUserTherapist && task?.status === ParentTaskStatus.TODO && (
                <Button
                  right={'0px'}
                  position="absolute"
                  onClick={() => {
                    setEditParentTaskValue(task!)
                    onGoBack?.()
                  }}
                  fontSize={16}
                  variant="ghost"
                >
                  <Edit />
                  <Text>Edit</Text>
                </Button>
              )}
            </CenterRow>
            <CenterColumn>
              <Text variant="light">{t('treatmentPlanPlusTask:title')}</Text>
              <Text variant="charcoalBody">{task?.title}</Text>
            </CenterColumn>
            <CenterColumn>
              <Text variant="light">{t('treatmentPlanPlusTask:description')}</Text>
              <Text variant="charcoalBody">{task?.description}</Text>
            </CenterColumn>

            {canComment ? (
              <CenterColumn>
                <CenterRow gap="5px">
                  <Text variant="light">{t('treatmentPlanPlusTask:comment')}</Text>
                  {canComment && !commentFormOpened && (
                    <Button
                      onClick={() => {
                        openCommentForm(true)
                      }}
                      fontSize={12}
                      variant="ghost"
                    >
                      <Edit fontSize="small" />
                      <Text>{t('treatmentPlanPlusTask:edit')}</Text>
                    </Button>
                  )}
                </CenterRow>

                {commentFormOpened ? (
                  <Flex flexDirection="column" gap={10} width={['100%', '100%', '100%', '360px', '350px']}>
                    <TextField
                      autoFocus={autoFocus}
                      onChange={(e) => setCommentValue(e.target.value)}
                      multiline
                      maxRows={5}
                      value={commentValue}
                      placeholder={t('treatmentPlanPlusTask:comment')}
                      fullWidth
                    />
                    <CenterRow width="100%" justifyContent="flex-end" gap={10}>
                      <Button
                        onClick={() => {
                          openCommentForm(false)
                        }}
                        p={1}
                        px={3}
                        variant="secondary"
                      >
                        {t('treatmentPlanPlusTask:cancel')}
                      </Button>
                      <Button
                        onClick={() => {
                          comment({
                            data: {
                              id: task?.id as number,
                              comment: commentValue,
                            },
                            afterEffect: () => {
                              openCommentForm(false)
                              setAutoFocus?.(false)
                              onGoBack?.()
                            },
                          })
                        }}
                        p={1}
                        px={3}
                      >
                        {isLoading ? <Spinner width="20px" /> : task?.comment ? t('treatmentPlanPlusTask:update') : t('treatmentPlanPlusTask:addComment')}
                      </Button>
                    </CenterRow>
                  </Flex>
                ) : (
                  <CenterRow width={['100%', '100%', '100%', '400px', '400px']} gap="10px">
                    <Text>{commentValue || '--'}</Text>
                  </CenterRow>
                )}
              </CenterColumn>
            ) : (
              <CenterColumn>
                <Text variant="light">{t('treatmentPlanPlusTask:comment')}</Text>
                <Text variant="charcoalBody">{task?.comment || '--'}</Text>
              </CenterColumn>
            )}
          </CenterColumn>
        )}
      </Flex>
    </Container>
  )
}
