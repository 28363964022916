const therapistRoot = 'therapists'
const userRoot = 'users'
/**
 * Initializing API routes, one centralized place for all API routes
 * Easier to maintain and update
 * */
export const apiRoutes = {
  parent: {
    addChild: 'parents/add-child-profile/',
    afterDiscovery: 'parents/send-email-after-discovery-call/',
  },
  therapist: {
    create: `${therapistRoot}/create/`,
    get: `${therapistRoot}/`,
  },
  user: {
    checkEmailForAuthenticated: `${userRoot}/check-mail-authenticated/`,
  },
  getSessionLengthPrice: 'calcom/get_variable_sessions/',
  submitFeedback: 'calcom/sessions/feedback/',
  landing: {
    failConsultation: 'landing/fail-consultation/',
  },
  createGoal: 'treatment_plan/create/goal/',
  updateGoal: 'treatment_plan/update/goal/',
  updateNoteVisibility: 'treatment_plan/update/note/visibility/',
  getTreatmentPlans: 'treatment_plan',
  getParentTasks: 'parent_task',
  createProgress: 'treatment_plan/create/progress/',
  getObjectives: 'treatment_plan/get/objectives',
  getProgress: 'treatment_plan/get/progress',
  updateObjectiveStatus: 'treatment_plan/update/objective/status/',
  getAllProfiles: 'parents/profiles/',
  parentTask: {
    create: 'parent_task/create/',
    update: 'parent_task/update/',
    comment: 'parent_task/comment/',
    getOne: 'parent_task/get',
    delete: 'parent_task/delete',
  },
  adminAnalytics: {
    conversions: 'admin_analytics/conversions',
    revenue: 'admin_analytics/revenue',
    activeUsers: 'admin_analytics/active-users',
    therapistPerformance: 'admin_analytics/therapist-performance',
  },
}
