import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'
import type { Goal, Objective, Progress } from './types'
import { treatmentPlanActions } from '.'
import { notify } from 'helpers'
type UpdateObjectiveStatusArgs = {
  id: number
  status: string
}
interface GoalPayload extends Goal {
  title: string
  id: number
  profile_id: number | string
  description: string
  objectives: Array<Objective>
  status: string
}

type ProgressArgs = {
  session_id: number
  objective_id: number | undefined
  quantitative_value?: number | undefined
  qualitative_value: string | undefined
  progress_id: number | undefined
}

type CreateProgressArgs = {
  data: Array<{
    session_id: number
    objective_id: number | undefined
    quantitative_value?: number | null | string | undefined
    qualitative_value?: string | null | undefined
  }>
  closeModal: () => void
}

const treatmentPlanService = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    createOrUpdateGoal: build.mutation<GoalPayload, { data: Partial<GoalPayload>; isUpdate: boolean; resetState?: () => void }>({
      query: ({ data, isUpdate }) => ({
        url: isUpdate ? apiRoutes.updateGoal : apiRoutes.createGoal,
        method: isUpdate ? 'PUT' : 'POST',
        body: data,
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }),
      invalidatesTags: ['get-goals', 'get-objectives', 'get-progresses', 'get-parent-tasks'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const successMessage = arg.isUpdate ? 'Goal successfully updated' : 'Goal successfully created'
          const response = await queryFulfilled
          if (response.data) {
            dispatch(treatmentPlanActions.closeGoalForm())
            arg.resetState?.()
            notify(successMessage, false)
          }
        } catch (error: any) {
          const errorMessage = arg.isUpdate ? 'Failed to update goal' : 'Failed to create goal'
          notify(errorMessage, true)
        }
      },
    }),
    getTreatmentPlans: build.query<Array<GoalPayload>, { profile_id: string | number }>({
      query: (data) => ({
        url: `${apiRoutes.getTreatmentPlans}?profile_id=${data.profile_id}`,
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['get-goals'],
    }),
    createProgress: build.mutation<any, CreateProgressArgs>({
      query: ({ data }) => ({
        url: apiRoutes.createProgress,
        method: 'POST',
        body: { progresses: data },
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }),
      invalidatesTags: ['get-goals', 'get-progresses', 'get-objectives'],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled
          if (response.data) {
            arg.closeModal()
            notify('Success', false)
          }
        } catch (error: any) {
          console.log(error)
          notify(error?.error?.data?.message ?? 'Failed to create progress', true)
        }
      },
    }),
    getProgress: build.query<Progress[], number>({
      query: (session_id) => ({
        url: apiRoutes.getProgress,
        method: 'GET',
        params: {
          session_id: session_id,
        },
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['get-progresses'],
    }),
    getObjectives: build.query<Array<Objective>, number | undefined>({
      query: (profile_id) => ({
        url: apiRoutes.getObjectives,
        method: 'GET',
        params: {
          profile_id,
        },
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['get-objectives'],
    }),
    updateObjectiveStatus: build.mutation<string, UpdateObjectiveStatusArgs>({
      query: (data) => ({
        url: apiRoutes.updateObjectiveStatus,
        method: 'PUT',
        body: data,
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }),
      invalidatesTags: ['get-goals'],
    }),
  }),
})

export const { useCreateOrUpdateGoalMutation, useGetTreatmentPlansQuery, useGetObjectivesQuery, useCreateProgressMutation, useGetProgressQuery, useUpdateObjectiveStatusMutation } =
  treatmentPlanService
