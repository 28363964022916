import { Autocomplete, TextField } from '@mui/material'
import { CenterRow, Flex, Text } from 'components/Core'
import { TreatmentPlan } from 'pages/ShardPage/TreatmentPlan/TreatmentPlan'
import { useEffect, useMemo, useState } from 'react'
import { type GetProfileResult, useGetAllParentProfilesQuery } from './services'
import Styles from 'components/ClientProfileComponents/GeneralInformation/Styles.module.scss'
import { getInitials } from 'utils'

export const ParentTreatmentPlan = () => {
  const [selectedProfile, setSelectedProfile] = useState<Partial<GetProfileResult>>({})

  const { data, isSuccess } = useGetAllParentProfilesQuery(null)
  const lastSelectedProfile = localStorage.getItem('lastSelectedProfile')
  const defaultValue = useMemo(() => {
    const result = data?.find((item) => String(item?.id) === lastSelectedProfile)
    return result
  }, [data, lastSelectedProfile, isSuccess])
  useEffect(() => {
    if (defaultValue) {
      setSelectedProfile(defaultValue)
    }
  }, [defaultValue])

  const ProfileSelector = () => {
    return (
      <CenterRow>
        <div className={Styles.main_container__header__initials}>{getInitials(selectedProfile?.full_name)}</div>
        <Autocomplete
          options={data ?? []}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: 20,
                  fontWeight: 'bold',
                  ':hover': {
                    cursor: 'pointer',
                  },
                },
              }}
            />
          )}
          renderOption={(props, option) => <li {...props}>{option?.full_name ?? ''}</li>}
          getOptionLabel={(option) => (option as Partial<GetProfileResult>)?.full_name ?? ''}
          sx={{
            maxWidth: '350px',
            width: '160px',
            '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
          }}
          onChange={(e, value) => {
            setSelectedProfile(value as GetProfileResult)
          }}
          value={selectedProfile}
          selectOnFocus={false}
          disableClearable
        />
      </CenterRow>
    )
  }

  return (
    <Flex flexDirection="column" width={['100%', '100%', '90%', '80%', '70%']} p={2} py={30} gap={30} margin="auto">
      <TreatmentPlan hideGoalStatus ProfileSelector={ProfileSelector} allowEdit={false} name={selectedProfile?.full_name} profile_id={selectedProfile?.id as number} />
    </Flex>
  )
}
