import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiPath } from '../../constants'

// initialize an empty api service that we'll inject endpoints into later as needed
export const rootServiceApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: apiPath }),
  endpoints: () => ({}),
  tagTypes: ['get-goals', 'get-progresses', 'get-objectives', 'ParentProfiles', 'get-parent-tasks', 'get-single-parent-task'],
  refetchOnReconnect: true,
})
