// Core
import { func } from 'prop-types'
import { useEffect, useState } from 'react'
// Styles
import Styles from './styles.module.scss'
// Instruments
import { yupResolver } from '@hookform/resolvers/yup'
import cuid from 'cuid'
import { useDropzone } from 'react-dropzone'
import { useForm, useWatch } from 'react-hook-form'
import * as Yup from 'yup'
//Utils
import { convertBytes } from '../../../../../utils'
// import { getPreviewImg } from '../../../../../utils'

// Components
import { ReactComponent as DeleteIcon } from '../../../../../theme/assets/icons/delete_new.svg'
import { ReactComponent as FileIcon } from '../../../../../theme/assets/icons/file_new.svg'
import { ReactComponent as PaperClipIcon } from '../../../../../theme/assets/icons/paper_clip.svg'
import { BorderButton } from '../../../../GeneralComponents/Buttons/BorderButton'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../i18n'
import ar from './locale/ar.json'
import en from './locale/en.json'
import { Button, CenterRow, Flex, TextArea } from 'components/Core'
import { TextField } from '@mui/material'

const schema = Yup.object().shape({
  textMessage: Yup.string().required('Please write your message').max(1000, 'Message is too long'),
})

export const NewMessageBar = ({ onSubmit, isMessageSending }) => {
  const { register, reset, control, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      textMessage: '',
    },
  })

  const message = useWatch({
    name: 'textMessage',
    control,
  })

  const { t } = useTranslation()

  const [files, setFiles] = useState([])
  const [isDisabled, setIsDisabled] = useState(true)

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'))

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('language'))
  }, [localStorage.getItem('language')])

  useEffect(() => {
    i18n.addResourceBundle('en', 'new_message', en)
    i18n.addResourceBundle('ar', 'new_message', ar)
  }, [])

  useEffect(() => {
    if ((message === '' && files.length === 0) || isMessageSending) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [message, files, isMessageSending])

  const onMessageSend = () => {
    if (message || files.length) {
      onSubmit(message, files)
      setFiles([])
      setValue('textMessage', '')
      reset()
    }
  }

  const getPreviewImg = (fileType, filePreview) => {
    switch (fileType) {
      case 'image/png':
        return (
          <div className={Styles.preview__item__preview__imgWrap}>
            <img
              src={filePreview}
              onLoad={() => {
                URL.revokeObjectURL(filePreview)
              }}
            />
          </div>
        )
      case 'image/jpeg':
        return (
          <div className={Styles.preview__item__preview__imgWrap}>
            <img
              src={filePreview}
              onLoad={() => {
                URL.revokeObjectURL(filePreview)
              }}
            />
          </div>
        )
      default:
        return (
          <div className={Styles.preview__item__preview__imgWrap}>
            <FileIcon />
          </div>
        )
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    // accept: {
    //   'image/jpeg': ['.jpeg', '.jpg'],
    //   'image/png': ['.png'],
    //   'application/pdf': ['.pdf'],
    //   'application/msword': ['.doc'],
    //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    //   'text/plain': ['.txt']
    // },
    onDrop: (acceptedFiles) => {
      const filesToAdd = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          id: cuid(),
        }),
      )

      setFiles((prev) => [...prev, ...filesToAdd])
    },
  })

  const deleteSelectedFile = (fileId) => {
    const newFilesArray = files?.filter((file) => file.id !== fileId)
    setFiles(newFilesArray)
  }

  const thumbs = files?.map((file) => {
    return (
      <div className={Styles.preview__item} key={file.id}>
        <div className={Styles.preview__item__preview}>
          {getPreviewImg(file?.type, file?.preview)}
          <div className={Styles.preview__item__preview__description}>
            <p className={Styles.preview__item__preview__description__name}>{file.name}</p>
            <p className={Styles.preview__item__preview__description__size}>{convertBytes(file.size)}</p>
          </div>
        </div>
        <div className={Styles.preview__item__deleteIconWrap} onClick={() => deleteSelectedFile(file.id)}>
          <DeleteIcon />
        </div>
      </div>
    )
  })

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview))
  }, [])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onMessageSend()
    }
  }

  return (
    <CenterRow flexWrap={['wrap', 'wrap', 'nowrap']} className={Styles.newMessageBar}>
      <div className={Styles.newMessageBar__innerWrap}>
        <form id="newMessageForm" className={Styles.newMessageBar__form} onKeyDown={handleKeyPress}>
          <CenterRow width="100%" gap={10} pr={10}>
            <TextField
              fullWidth
              maxRows={5}
              multiline
              name="textMessage"
              control={control}
              placeholder={t('new_message:new_message')}
              {...register('textMessage')}
              class={Styles.newMessageBar__textField__inputWrap}
            />
            <div className={Styles.newMessageBar__textField__uploader}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <PaperClipIcon />
              </div>
            </div>
          </CenterRow>
        </form>
        {files.length !== 0 && <aside className={Styles.preview}>{thumbs}</aside>}
      </div>
      {/* <BorderButton title={t('new_message:send')} class={Styles.newMessageBar__btn} onClick={onMessageSend} disabled={isDisabled} /> */}
      <Button width={['100%', '100%', 'auto']} onClick={onMessageSend} disabled={isDisabled} variant="secondary">
        {t('new_message:send')}
      </Button>
    </CenterRow>
  )
}

NewMessageBar.propTypes = {
  onSubmit: func,
}
