import * as React from 'react'
import Popover from '@mui/material/Popover'
import Button from '@mui/material/Button'
import { DateRangePicker, RangeKeyDict } from 'react-date-range'
import { CenterRow } from 'components/Core'
import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'

type Props = {
  startDate: Date | undefined
  endDate: Date | undefined
  handelSelect: (ranges: RangeKeyDict) => void
  handleClear: () => void
}
export function DateRangePopover({ endDate, startDate, handelSelect, handleClear }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <CenterRow>
        {startDate && endDate ? (
          <Button onClick={handleClick} variant="outlined">
            <CenterRow gap="10px">
              <span>{startDate.toLocaleDateString()}</span>--
              <span>{endDate.toLocaleDateString()}</span>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  handleClear()
                }}
              >
                <Close />
              </IconButton>
            </CenterRow>
          </Button>
        ) : (
          <Button aria-describedby={id} variant="outlined" onClick={handleClick}>
            Select date range
          </Button>
        )}
      </CenterRow>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DateRangePicker
          displayMode="dateRange"
          ranges={[
            {
              startDate: startDate ?? new Date(),
              endDate: endDate ?? new Date(),
              key: 'selection',
            },
          ]}
          onChange={handelSelect}
        />
      </Popover>
    </div>
  )
}
