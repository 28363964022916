export const requestMessages: RequestMessage = {
  profile_updated: {
    en: 'Your profile has been updated successfully!',
    ar: 'تم تحديث ملفك الشخصي بنجاح!',
  },
  profile_updated_error: {
    en: 'There was an error updating your profile. Please try again.',
    ar: 'حدث خطأ أثناء تحديث ملفك الشخصي. يرجى المحاولة مرة أخرى.',
  },
  'The old password is not correct': {
    en: 'The old password is not correct',
    ar: 'كلمة المرور القديمة غير صحيحة',
  },
  unAuthorizedToViewZoomMeeting: {
    en: 'You are not authorized to view this zoom meeting',
    ar: 'أنت غير مصرح لك بالمشاركة في هذه الاجتماع',
  },
  meetingNotFound: {
    en: 'Meeting not found',
    ar: 'الاجتماع غير موجود',
  },
  commentedSuccessfully: {
    en: 'Commented successfully',
    ar: 'تم التعليق بنجاح',
  },
  updatedSuccessfully: {
    en: 'Updated Successfully',
    ar: 'تم التحديث بنجاح',
  },
  goalUpdatedSuccessfully: {
    en: 'Goal updated successfully',
    ar: 'تم تحديث الهدف بنجاح',
  },
  goalCreatedSuccessfully: {
    en: 'Goal created successfully',
    ar: 'تم إنشاء الهدف بنجاح',
  },
  taskUpdatedSuccessfully: {
    en: 'Task updated successfully',
    ar: 'تم تحديث المهمة بنجاح',
  },
  taskCreatedSuccessfully: {
    en: 'Task created successfully',
    ar: 'تم إنشاء المهمة بنجاح',
  },
}

export type RequestMessage = {
  [key: string]: TranslationKeys
}

export type TranslationKeys = {
  [key: string]: string
}
