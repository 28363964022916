import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { ParentTask, ParentTaskState } from './types'
import { objectiveTypes } from 'constants/index'
import { useDispatch } from 'react-redux'

const initialState: ParentTaskState = {
  parentTaskFormOpened: false,
  profile_id: undefined,
  filters: {},
  parentTask: {
    id: undefined,
    title: '',
    description: '',
    status: '',
    comment: '',
    objective: undefined,
    created_at: '',
    updated_at: '',
  },
}

export const parentTaskSlice = createSlice({
  name: 'parentTask',
  initialState,
  reducers: {
    addDateFilters(state, action: PayloadAction<string | undefined>) {
      //  use Set to remove duplicates
      state.filters.date = action.payload
    },
    removeDateFilters(state) {
      state.filters.date = undefined
    },
    toggleParentForm(state) {
      state.parentTaskFormOpened = !state.parentTaskFormOpened
    },
    openParentTaskForm(state) {
      state.parentTaskFormOpened = true
    },
    closeParentTaskForm(state) {
      state.parentTaskFormOpened = false
      state.parentTask = initialState.parentTask
    },
    resetState(state) {
      state.parentTask = initialState.parentTask
      state.parentTaskFormOpened = initialState.parentTaskFormOpened
    },
    setEditParentTaskValue(state, action: PayloadAction<ParentTask>) {
      state.parentTask = action.payload
      state.parentTaskFormOpened = true
    },
    setSelectedProfileId(state, action: PayloadAction<number>) {
      state.profile_id = action.payload
    },
  },
})

export const useParentTaskSlice = () => {
  const { actions } = parentTaskSlice
  const dispatch = useDispatch()

  const resetState = () => {
    dispatch(actions.resetState())
  }
  const setEditParentTaskValue = (task: ParentTask) => {
    dispatch(actions.setEditParentTaskValue(task))
  }
  const addDateFilters = (date: string | undefined) => {
    dispatch(actions.addDateFilters(date))
  }
  const removeDateFilter = () => {
    dispatch(actions.removeDateFilters())
  }

  return {
    resetState,
    setEditParentTaskValue,
    addDateFilters,
    removeDateFilter,
    dispatch,
    actions,
  }
}

export const parentTaskActions = parentTaskSlice.actions
export const parentTaskReducer = parentTaskSlice.reducer
