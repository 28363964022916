import { Delete } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Box, Button, CenterRow, Container, Flex, Text } from 'components/Core'
import { TextAreaField, TextField, TextFieldAutocomplete } from 'components/GeneralComponents'
import type { Goal, Objective } from './slice/types'
import { goalStatusDropDownList, objectiveInitialValue, objectivesDropDownList, objectiveStatusDropDownList, objectiveTypes } from 'constants/index'
import { useTreatmentPlanSlice } from './slice'
import type { Control, FieldErrors, FieldValues, UseFormHandleSubmit, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { useCreateOrUpdateGoalMutation } from './slice/services'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { useEffect, useMemo, useState } from 'react'
import { ConfirmationModal } from 'components/shared/ConfimationModal'
import { useDisclosure } from 'hooks/useDisclosure'
import { notify } from 'helpers'
import { cloneDeep, isEqual, pick } from 'lodash'
import { MuiTextField } from 'components/Core/TextArea/MuiTextField'

type Props = {
  open: boolean
  errors: FieldErrors<FieldValues>
  control: Control<FieldValues, any>
  watchDescription: string
  goal: Goal
  objectives: Objective[]
  profile_id: number | string
  handleSubmit: UseFormHandleSubmit<FieldValues, undefined>
  restObjectiveFormState: (index: number) => void
  register: UseFormRegister<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  resetFormState: () => void
  restReduxState: () => void
  closeGoalForm: () => void
  watch: UseFormWatch<FieldValues>
}
export const GoalForm = ({
  control,
  profile_id,
  errors,
  open,
  goal,
  objectives,
  watchDescription,
  handleSubmit,
  closeGoalForm,
  resetFormState,
  restReduxState,
  register,
  setValue,
  restObjectiveFormState,
  watch,
}: Props) => {
  // const hasFormChanged = (prevValues: any, currentValues: FieldValues) => {
  //   // Compare goal basic fields
  //   const basicFieldsChanged = !isEqual(
  //     {
  //       title: prevValues?.title,
  //       description: prevValues?.description,
  //       status: prevValues?.['goal-status']?.value,
  //     },
  //     {
  //       title: currentValues?.title,
  //       description: currentValues?.description,
  //       status: currentValues?.['goal-status']?.value,
  //     },
  //   )

  //   // Get number of objectives from keys
  //   const objectiveCount = Object.keys(currentValues).filter((key) => key.startsWith('objective-title')).length

  //   // Compare objectives
  //   const objectivesChanged = Array.from({ length: objectiveCount }).some((_, index) => {
  //     const prevObj = {
  //       title: prevValues?.[`objective-title-${index}`],
  //       description: prevValues?.[`objective-description-${index}`],
  //       status: prevValues?.[`objective-status-${index}`]?.value,
  //       type: prevValues?.[`objective-type-${index}`]?.value,
  //     }

  //     const currentObj = {
  //       title: currentValues?.[`objective-title-${index}`],
  //       description: currentValues?.[`objective-description-${index}`],
  //       status: currentValues?.[`objective-status-${index}`]?.value,
  //       type: currentValues?.[`objective-type-${index}`]?.value,
  //     }

  //     return !isEqual(prevObj, currentObj)
  //   })

  //   return basicFieldsChanged || objectivesChanged
  // }

  // Add state to track original values
  const [originalValues, setOriginalValues] = useState<any>()
  const transformGoalToFormStructure = (goal: Goal | undefined) => {
    const transformed: { [key: string]: any } = {
      title: goal?.title,
      description: goal?.description,
      'goal-status': { value: goal?.status },
    }

    // Transform objectives
    goal?.objectives?.forEach((obj, index) => {
      transformed[`objective-type-${index}`] = { value: obj.type }
      transformed[`objective-title-${index}`] = obj.title
      transformed[`objective-description-${index}`] = obj.description
      transformed[`objective-status-${index}`] = { value: obj.status }
    })

    return transformed
  }
  useEffect(() => {
    if (goal) {
      if (!originalValues) {
        const formattedGoal = transformGoalToFormStructure(goal)
        setOriginalValues(cloneDeep(formattedGoal))
      }
    }
  }, [goal])

  // Use watch to track form changes
  // const currentValues = watch()

  const [createOrUpdate, { isLoading }] = useCreateOrUpdateGoalMutation()
  const [objectiveIndex, setObjectiveIndex] = useState<number>()
  const { onClose, onOpen, open: openModal } = useDisclosure()
  const isUpdate = useMemo(() => goal.id !== undefined, [goal.id])

  const onSubmit = () => {
    const activeObjective = goal.objectives.filter((objective) => !objective.is_deleted)
    if (!activeObjective.length) {
      return notify('Please add at least one objective')
    }
    createOrUpdate({
      data: { ...goal, profile_id },
      isUpdate,
      resetState: () => {
        resetFormState()
        restReduxState()
      },
    })
  }
  const { addObjectives, hardDeleteObjective, softDeleteObjective, updateObjective, updateGoal } = useTreatmentPlanSlice()
  const onDeleteConfirm = () => {
    if (objectiveIndex !== undefined) {
      if (isUpdate && objectives?.[objectiveIndex].id) {
        softDeleteObjective(objectiveIndex)
      } else {
        hardDeleteObjective(objectiveIndex)
      }
      restObjectiveFormState(objectiveIndex)
    }
  }
  return (
    <>
      <Flex gap={20} width={open ? ['100%', '100%', '100%', '100%', '95%', '70%'] : '100%'} height={['100%']} flexDirection={['column']}>
        <Text variant="heading4">{isUpdate ? 'Update Goal' : 'Add Goal'}</Text>
        <TextField
          //@ts-ignore
          onTextChange={(value: string) => updateGoal({ title: value })}
          label={'Goal Title'}
          requiredIcon={' *'}
          placeholder={'Title'}
          error={errors?.title}
          register={register('title')}
          value={goal.title}
        />
        {isUpdate && (
          <TextFieldAutocomplete
            //@ts-ignore
            register={register(`goal-status`)}
            disablePortal={true}
            name={'goal-status'}
            control={control}
            options={goalStatusDropDownList}
            setValue={(name, selectedValue, option) => {
              updateGoal({ status: selectedValue?.value || undefined })
              setValue(name, selectedValue, option)
            }}
            error={errors['goal-status']}
            label="Goal Status"
            sx={{ width: '100%' }}
            width="100%"
            // requiredIcon={'*'}
            freeSolo={false}
            disabled={false}
            value={{ value: goal.status }}
          />
        )}
        <MuiTextField
          // {...register('description')}
          placeholder="Insert description here..."
          name="description"
          label="Goal Description (Optional)"
          // control={control}
          error={Boolean(errors.description)}
          maxLength={500}
          showCharacterCounter
          onChange={(e) => {
            setValue('description', e.target.value)
            // updateGoal({ description: e.target.value })
          }}
          value={goal.description ?? watchDescription}
        />
        <Flex flexDirection={['column']} gap={2}>
          <Text variant="heading4">Add Objectives</Text>
          <Text p={3} bg="gray.100">
            You can breakdown goals into short term objective to measure progress
          </Text>
        </Flex>
        {objectives?.map((objective, index) =>
          !objective.is_deleted ? (
            <Flex bg="gray.100" flexDirection={['column']} p={3} gap={16} borderColor={'gray.300'} pb={3} width="100%" key={index} position="relative">
              <Box zIndex={10} position="absolute" top={0} right={0}>
                <IconButton
                  color="error"
                  sx={{ borderRadius: 0 }}
                  onClick={() => {
                    setObjectiveIndex(index)
                    onOpen()
                  }}
                >
                  <Delete style={{ cursor: 'pointer' }} />
                </IconButton>
              </Box>
              <CenterRow flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']} gap={'10px'} flexGrow={1}>
                <TextFieldAutocomplete
                  //@ts-ignore
                  register={register(`objective-type-${index}`)}
                  disablePortal={true}
                  name={`objective-type-${index}`}
                  placeholder={'Objective Type'}
                  control={control}
                  options={objectivesDropDownList}
                  setValue={(name, selectedValue, option) => {
                    updateObjective({ index, value: { type: selectedValue.value } })
                    setValue(name, selectedValue, option)
                  }}
                  error={errors[`objective-type-${index}`]}
                  label="Objective Type"
                  sx={{ width: '100%' }}
                  width="100%"
                  requiredIcon={'*'}
                  freeSolo={false}
                  disabled={false}
                  value={{ value: objective.type }}
                />
                <Box width={'100%'}>
                  <MuiTextField
                    // {...register(`objective-title-${index}`)}
                    name={`objective-title-${index}`}
                    onChange={(e) => {
                      updateObjective({ index, value: { title: e.target.value } })
                      setValue(`objective-title-${index}`, e.target.value, { shouldValidate: true })
                    }}
                    label={`Objective Title`}
                    required
                    placeholder={'Objective'}
                    error={errors?.[`objective-title-${index}`] != undefined}
                    value={objective.title}
                    fullWidth
                    helperText={errors?.[`objective-title-${index}`]?.message as string}
                  />
                </Box>
              </CenterRow>
              <MuiTextField
                placeholder={'Description'}
                onChange={(e) => updateObjective({ index, value: { description: e.target.value } })}
                error={errors?.[`objective-description-${index}`] != undefined}
                maxLength={255}
                name={`objective-description-${index}`}
                multiline
                maxRows={4}
                value={objective.description}
                label="Objective Description (Optional)"
                showCharacterCounter
              />
              {objective.type === objectiveTypes.QUANTITATIVE && (
                <CenterRow flexWrap={'wrap'} gap={10}>
                  <Box flexGrow={1} position="relative">
                    <TextField
                      //@ts-ignore
                      onTextChange={(baseline_percentage: number) => {
                        updateObjective({ index, value: { baseline_percentage } })
                        setValue(`objective-baseline-percentage-${index}`, baseline_percentage, { shouldValidate: true })
                      }}
                      label={'Baseline percentage %'}
                      placeholder={'Baseline percentage'}
                      register={register(`objective-baseline-percentage-${index}`)}
                      requiredIcon={'*'}
                      value={objective.baseline_percentage}
                      error={errors?.[`objective-baseline-percentage-${index}`]}
                      type="number"
                    />
                    <Container height={30} width={30} bg="gray.100" position="absolute" top={40} right={10}>
                      %
                    </Container>
                  </Box>
                  <Box flexGrow={1} position="relative">
                    <TextField
                      //@ts-ignore
                      onTextChange={(target_percentage: number) => {
                        updateObjective({ index, value: { target_percentage } })
                        setValue(`objective-target-percentage-${index}`, target_percentage, { shouldValidate: true })
                      }}
                      label={'Target percentage %'}
                      placeholder={'Target percentage'}
                      register={register(`objective-target-percentage-${index}`)}
                      requiredIcon={'*'}
                      value={objective.target_percentage}
                      error={errors?.[`objective-target-percentage-${index}`]}
                      type="number"
                    />
                    <Container height={30} width={30} bg="gray.100" position="absolute" top={40} right={10}>
                      %
                    </Container>
                  </Box>
                </CenterRow>
              )}
              {objective.id && (
                <TextFieldAutocomplete
                  //@ts-ignore
                  register={register(`objective-status-${index}`)}
                  disablePortal={true}
                  name={`objective-status-${index}`}
                  control={control}
                  options={objectiveStatusDropDownList}
                  setValue={(name, selectedValue, option) => {
                    updateObjective({ index, value: { status: selectedValue.value } })
                    setValue(name, selectedValue, option)
                  }}
                  // customOnChange={(selectedValue: { value: { value: string } }) => {
                  //   updateObjective({ index, value: { status: selectedValue.value.value } })
                  // }}
                  error={errors[`objective-status-${index}`]}
                  label="Objective Status"
                  sx={{ width: '100%' }}
                  width="100%"
                  requiredIcon={'*'}
                  value={{ value: objective.status }}
                />
              )}
            </Flex>
          ) : null,
        )}
        <Button
          variant="secondary"
          onClick={() => {
            addObjectives(objectiveInitialValue)
          }}
        >
          Add Objective
        </Button>
      </Flex>
      <CenterRow justifyContent={['flex-end']} width="100%" gap={10}>
        <Button
          onClick={() => {
            closeGoalForm()
            resetFormState()
            restReduxState()
          }}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          // disabled={isUpdate && !hasFormChanged(originalValues, currentValues)}
          minWidth={100}
          onClick={() => {
            handleSubmit(onSubmit)()
          }}
        >
          {isLoading ? <Spinner width="25px" /> : goal.id ? 'Update' : 'Create'}
        </Button>
      </CenterRow>
      <ConfirmationModal title="Delete Objective" onClose={onClose} message="Are you sure you want to delete this objective?" open={openModal} onConfirm={onDeleteConfirm} />
    </>
  )
}
