// import { Chip } from '@mui/material'
import type { Goal } from './slice/types'
import { Box, Button, CenterRow, Chip, type ChipTypes, Flex, Text } from 'components/Core'
import { goalStatus, goalStatusDropDownList, goalStatusMap, objectiveStatus, objectiveStatusDropDownList, objectiveStatusMap } from 'constants/index'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Progresses } from './Progresses'
import { Edit } from '@mui/icons-material'
import { ChipWithMenu } from 'components/shared/ChipWithMenu'
import { useCreateOrUpdateGoalMutation, useUpdateObjectiveStatusMutation } from './slice/services'
import { useTranslation } from 'react-i18next'

type Props = {
  goal: Goal
  allowEdit?: boolean
  setEditGoalValue: (goal: Goal) => void
  hideGoalStatus?: boolean
  isLoadingPlans: boolean
  profile_id: number
}
export const GoalList = ({ goal, setEditGoalValue, allowEdit, hideGoalStatus, isLoadingPlans, profile_id }: Props) => {
  const { t } = useTranslation()
  const [updateGoal, { isLoading: updatingGoal }] = useCreateOrUpdateGoalMutation()

  const [objectiveIndex, setObjectiveIndex] = useState<number>()
  const objectiveStatusColor = useCallback((status: string | undefined): ChipTypes | undefined => {
    if (status === objectiveStatus.NOT_STARTED) {
      return 'error'
    }
    if (status === objectiveStatus.IN_PROGRESS) {
      return 'warning'
    }
    if (status === objectiveStatus.COMPLETED) {
      return 'success'
    }
  }, [])
  const goalChipType = useMemo<ChipTypes>(() => {
    if (goal.status === goalStatus.ACCOMPLISHED) {
      return 'success'
    }
    return 'warning'
  }, [goal.status])
  const [updateStatus, { isLoading }] = useUpdateObjectiveStatusMutation()
  const objectiveStatusDropDown = useCallback(
    (status: string) => {
      return objectiveStatusDropDownList.filter((item) => item.value !== status).filter((item) => item.value !== objectiveStatus.NOT_STARTED)
    },
    [goal],
  )
  return (
    <>
      <CenterRow borderRadius={1} bg="gray.100" p={3} flexDirection={['column']} width="100%" gap={'10px'} justifyContent={['space-between']}>
        <Box width="100%" pb={2} borderBottom={1}>
          <CenterRow width="100%" justifyContent={['space-between']}>
            <CenterRow flexWrap={['wrap']} gap={2}>
              <Text borderRight={!hideGoalStatus ? 1 : 'none'} pr={2} fontSize={25} fontWeight="bold">
                {goal.title}
              </Text>
              {!hideGoalStatus && (
                <ChipWithMenu
                  value={{ label: t(`treatmentPlanPlusTask:${goalStatusMap[goal.status as keyof typeof goalStatusMap]}`) as string, value: goal.status as string }}
                  options={goalStatusDropDownList}
                  allowEdit={allowEdit && goal.status !== goalStatus.ACCOMPLISHED}
                  onChange={(value) => {
                    updateGoal({
                      data: {
                        ...goal,
                        status: value.value as string,
                        profile_id,
                      },
                      isUpdate: true,
                    })
                  }}
                  type={goalChipType}
                  isLoading={updatingGoal}
                />
              )}
            </CenterRow>
            {allowEdit && (
              <Button onClick={() => setEditGoalValue(goal)} fontSize={16} variant="ghost">
                <Edit />
                <Text>Edit</Text>
              </Button>
            )}
          </CenterRow>
          <CenterRow width="100%" justifyContent={['space-between']}>
            <Text maxWidth="90%" color="textLightGray" variant="body2">
              {goal.description}
            </Text>
          </CenterRow>
        </Box>
        <Flex gap={10} pl={[0, 0, 3]} width="100%" flexDirection={['column']}>
          <CenterRow mb={2} width="100%">
            <Text borderBottom={1} textAlign="start" fontSize={20} color="black.700">
              {t('treatmentPlanPlusTask:objectives')} ({goal?.objectives?.length})
            </Text>
          </CenterRow>
          {goal.objectives.map((objective, index) => (
            <Flex pl={[0, 0, 2]} gap={[2, 2, 1]} flexDirection={['column']} key={index} mt={2} width="100%" justifyContent={['space-between']}>
              <Text fontSize={18} color="black.700">
                {objective.title}
              </Text>
              <CenterRow gap={10} flexWrap="wrap" width="100%" justifyContent={['space-between']}>
                <Flex gap={2} flexDirection="column">
                  <Text color="textLightGray">{objective.description}</Text>
                </Flex>
                <CenterRow gap={2}>
                  <ChipWithMenu
                    isLoading={objectiveIndex === index ? isLoading || isLoadingPlans : false}
                    type={objectiveStatusColor(objective.status)}
                    onChange={(value) => {
                      setObjectiveIndex(index)
                      updateStatus({
                        id: objective.id as number,
                        status: value.value,
                      })
                    }}
                    options={objectiveStatusDropDown(objective.status as string)}
                    value={{
                      value: objective.status as string,
                      label: t(`treatmentPlanPlusTask:${objectiveStatusMap[objective.status as keyof typeof objectiveStatusMap]}`) as string,
                    }}
                    allowEdit={allowEdit && objective.status !== objectiveStatus.COMPLETED}
                  />
                  <Chip type="info" value={`${objective.progresses?.length} ${t('treatmentPlanPlusTask:sessions')}`} />
                </CenterRow>
              </CenterRow>
              <CenterRow mt={2} flexWrap="wrap" width="100%" justifyContent={['space-between']}>
                <Progresses
                  t={t}
                  isLoadingPlans={isLoadingPlans}
                  progresses={objective.progresses}
                  type={objective.type}
                  baselinePercentage={objective.baseline_percentage}
                  targetPercentage={objective.target_percentage}
                  objectiveCreatedAt={objective.created_at as string}
                />
              </CenterRow>
            </Flex>
          ))}
        </Flex>
      </CenterRow>
    </>
  )
}
