import { Alert, CircularProgress } from '@mui/material'
import { Box, Button, CenterRow, Container, Text, TextArea } from 'components/Core'
import { OutlinedCard } from 'components/OutlinedCard/OulinedCard'
import { useEffect, useState } from 'react'
import { ThumbsDown, ThumbsUp } from 'theme/assets/icons'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from 'shardSelectors/seletors'
import { SuccessLottie } from 'components/SuccessLottie/SuccessLottie'
import { parentActions } from 'actions'
import { useFeedBackMutation, useGetFeedbackQuery } from 'components/FeedbackModal/service'
import { arabicFeedbackContent } from './locale/arabicFeedbackContent'
import { englishFeedbackContent } from './locale/englishFeedbackContent'
import { Link, useSearchParams } from 'react-router-dom'
import { userTypes } from 'constants/index'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { selectZoomLeaveUrl } from 'defaultLayout/slice/selectors'
import { useContentBundle } from 'hooks/useContentBundle'

type ErrorType = { data: { message: string } }
type Feedback = { feedback: 'good' | 'bad' | undefined; tell_us_more?: string }

export const FeedbackForm = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const [showFeedbackForm, setShowFeedbackForm] = useState(false)
  const [uiError, setUiError] = useState<string>('')
  const [feedback, setFeedBack] = useState<Feedback>({ feedback: undefined, tell_us_more: '' })
  const meetingId = searchParams.get('id')
  const [submitFeedback, { isLoading, error, isSuccess, reset }] = useFeedBackMutation()
  const user = useSelector(selectUser)
  const { direction } = useGetLanguage({ userType: user?.user_type })

  const singleFeedback = useGetFeedbackQuery({ meeting_id: meetingId, token: user?.user?.token }, { skip: !user?.user?.token })

  useEffect(() => {
    if (isSuccess && user?.user?.token && user.user_type === userTypes.parent) {
      dispatch(parentActions.getTherapyInfo({ token: user?.user?.token }) as any)
    }
  }, [isSuccess, user?.user?.token])

  const { t } = useContentBundle({
    arabicContent: arabicFeedbackContent,
    englishContent: englishFeedbackContent,
    key: 'feedback',
  })

  const zoomLeaveUrl = useSelector(selectZoomLeaveUrl)

  useEffect(() => {
    if (singleFeedback.data === true) {
      window.location.replace(zoomLeaveUrl)
    }
    setTimeout(() => {
      setShowFeedbackForm(true)
    }, 2500)
  }, [singleFeedback.data, zoomLeaveUrl])

  // Zoom Meeting Id

  return (
    <Container direction={direction} height="100%">
      {singleFeedback.isLoading || !showFeedbackForm ? (
        <CircularProgress />
      ) : isSuccess ? (
        <CenterRow pb={[30]} flexDirection="column" gap={20}>
          <Box width={100} height={100}>
            <SuccessLottie />
          </Box>
          <Text fontSize={20}>{t('feedback:success')}</Text>

          <Button onClick={() => window.location.replace(zoomLeaveUrl)}>{t('feedback:goBack')}</Button>
        </CenterRow>
      ) : (
        <Box>
          <Box mb={10} alignSelf="center" width={['298px']}>
            {error || (uiError && !feedback.feedback) ? <Alert severity="error">{t(`feedback:errors:${(error as unknown as ErrorType)?.data?.message ?? uiError}`)}</Alert> : null}
          </Box>
          <Container gap={42} pb={30} flexDirection="column">
            <Text textAlign="center" variant="heading4">
              {t('feedback:title')}
            </Text>
            <CenterRow width="100%" justifyContent="center" gap={30}>
              <OutlinedCard
                onClick={() => setFeedBack((prev) => ({ ...prev, tell_us_more: '', feedback: 'good' }))}
                selected={feedback.feedback === 'good'}
                boxShadow="base"
                width={90}
                height={75}
              >
                <Container flexDirection="column" gap={'5px'}>
                  <ThumbsUp width={30} />
                  <Text>{t('feedback:good')}</Text>
                </Container>
              </OutlinedCard>
              <OutlinedCard onClick={() => setFeedBack((prev) => ({ ...prev, feedback: 'bad' }))} selected={feedback.feedback === 'bad'} width={90} height={75}>
                <Container flexDirection="column" gap={'5px'}>
                  <ThumbsDown width={30} />
                  <Text>{t('feedback:bad')}</Text>
                </Container>
              </OutlinedCard>
            </CenterRow>
            {feedback.feedback === 'bad' && (
              <Box>
                <CenterRow mb={10} gap={1}>
                  <Text>{t('feedback:tellUsMore')}</Text>
                  <Text color="black.500">({t('feedback:optional')})</Text>
                </CenterRow>
                <TextArea
                  style={{ resize: 'none' }}
                  height={119}
                  maxHeight={119}
                  width={['298px']}
                  maxWidth={['298px']}
                  placeholder={t('feedback:tellUsMore')}
                  value={feedback.tell_us_more}
                  onChange={(e) => setFeedBack((prev) => ({ ...prev, tell_us_more: e.target.value }))}
                />
              </Box>
            )}
            <Button
              onClick={() => {
                setUiError('')
                if (['good', 'bad'].includes(feedback.feedback as string)) {
                  submitFeedback({
                    body: {
                      feedback: feedback.feedback,
                      tell_us_more: feedback.tell_us_more,
                      meeting_id: meetingId,
                    },
                    token: user?.user?.token,
                  })
                } else {
                  setUiError('selectFeedback')
                }
              }}
              width={['298px']}
              borderRadius={['10px']}
              fontWeight="bold"
              variant="primary"
            >
              {isLoading ? <CircularProgress color="inherit" size={25} /> : t('feedback:submit')}
            </Button>
            <Link to="/">
              <Button fontSize={17} variant="ghost" color="textLightGray">
                {t('feedback:skipForNow')}
              </Button>
            </Link>
          </Container>
        </Box>
      )}
    </Container>
  )
}
