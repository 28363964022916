import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Switch, Tooltip } from '@mui/material'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { CenterRow, Flex, Text } from 'components/Core'
import { ChangeEvent } from 'react'
type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value: boolean
  isLoading: boolean
  index?: number
  changingSwitchIndex?: number
}
export const NoteVisibilitySwitch = ({ value, isLoading, changingSwitchIndex, index, onChange }: Props) => {
  return (
    <Flex gap="10px" flexDirection={['column']}>
      <CenterRow>
        <Tooltip title={value ? 'Hide note from parent' : 'Make note visible to the parent'}>
          <Switch defaultChecked={value} onChange={onChange} />
        </Tooltip>
        {value ? (
          isLoading && changingSwitchIndex === index ? (
            <Spinner width="20px" />
          ) : (
            <CenterRow gap="5px">
              <Visibility />
            </CenterRow>
          )
        ) : isLoading && changingSwitchIndex === index ? (
          <Spinner width="20px" />
        ) : (
          <CenterRow gap="5px">
            <VisibilityOff />
          </CenterRow>
        )}
      </CenterRow>
    </Flex>
  )
}
