import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material'
import { PaginatedUserResponse } from 'components/AdminPanel/AdminAnalytics/service'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { Box } from 'components/Core'
import { currencyFormatter } from 'utils/utils'
import { useState, useMemo } from 'react'

type Order = 'asc' | 'desc'

type Props = {
  isFetching: boolean
  data: PaginatedUserResponse | undefined
  page: number
  pageSize: number
  goToPage: (page: number) => void
  changePageSize: (pageSize: number) => void
  therapistView?: boolean
}

export const MuiTable = ({ data, isFetching, changePageSize, goToPage, page, pageSize, therapistView }: Props) => {
  const [orderBy, setOrderBy] = useState<keyof PaginatedUserResponse['results'][0] | ''>('')
  const [order, setOrder] = useState<Order>('asc')

  const handleSort = (field: keyof PaginatedUserResponse['results'][0]) => {
    const isAsc = orderBy === field && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(field)
  }

  const sortedData = useMemo(() => {
    if (!orderBy || !data?.results) return data?.results

    return [...data.results].sort((a, b) => {
      const aValue = a[orderBy]
      const bValue = b[orderBy]

      if (order === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0
      } else {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0
      }
    })
  }, [data?.results, order, orderBy])

  const renderSortLabel = (field: keyof PaginatedUserResponse['results'][0], label: string) => (
    <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>
      <TableSortLabel active={orderBy === field} direction={orderBy === field ? order : 'asc'} onClick={() => handleSort(field)}>
        {label}
      </TableSortLabel>
    </TableCell>
  )

  return (
    <>
      <Table sx={{ backgroundColor: 'white' }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>#</TableCell>
            {renderSortLabel('full_name', therapistView ? 'Therapist Name' : 'Parent Name')}
            {renderSortLabel('non_paid_session', 'Non Paid Session')}
            {renderSortLabel('paid_sessions', 'Paid Session')}
            {renderSortLabel('session_count', 'Session Count')}
            {renderSortLabel('revenue', 'Revenue')}
          </TableRow>
        </TableHead>

        <TableBody>
          {!isFetching ? (
            sortedData?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1 + page * pageSize}</TableCell>
                <TableCell sx={{ fontSize: 16, textWrap: 'initial' }}>{item.full_name}</TableCell>
                <TableCell sx={{ fontSize: 16 }}>{item.non_paid_session}</TableCell>
                <TableCell sx={{ fontSize: 16 }}>{item.paid_sessions}</TableCell>
                <TableCell sx={{ fontSize: 16 }}>{item.session_count}</TableCell>
                <TableCell sx={{ fontSize: 16 }}>{currencyFormatter({ amount: item.revenue })}</TableCell>
              </TableRow>
            ))
          ) : (
            <Box position="absolute" right={0} left={0} margin="auto">
              <Spinner />
            </Box>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={data?.count || 0}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={(_, value) => goToPage(value)}
        onRowsPerPageChange={(event) => {
          changePageSize(+event.target.value)
          goToPage(0)
        }}
      />
    </>
  )
}
