//MUI
import { Modal } from '@mui/material'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close.svg'
//Core
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
//Actions
import { therapistActions } from '../../../../actions'
//Components
import { Spinner } from '../../../ChatSystem/Common/Spinner'
//Data
import { GeneralProfileInformation, ParentProfileQuestionnairePart } from '../../../ClientProfileComponents'
import { childOnboardingQuestions } from '../../../../data'
import { Box, Flex } from 'components/Core'
import { ParentProfileQuestionnairePartWithTranslation } from 'components/ClientProfileComponents/QuestionnairePartWithTranslation'

export const ChildInfoPopup = ({ open, onClose, screeningId, isTherapist }) => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth.user)
  const currentProfile = useSelector((state) => state.therapist.oneApplication)

  const [profileInfo, setProfileInfo] = useState(null)
  const [clientInfo, setClientInfo] = useState(null)
  const [advisorInfo, setAdvisorInfo] = useState(null)
  const [questionnaire, setQuestionnaire] = useState(null)

  useEffect(() => {
    const data = {
      token: user?.token,
      id: screeningId,
    }
    dispatch(therapistActions.getOneApplication(data))
  }, [screeningId, user])

  const returnNewOnboarding = () => {
    if (currentProfile?.questionnaire?.questionnaire) {
      const finalOnboarding = []

      try {
        const onboardingQuestions = Array.isArray(currentProfile?.onboarding) ? currentProfile?.onboarding : Object.values(currentProfile?.onboarding || {})
        for (const sections of currentProfile?.questionnaire?.questionnaire) {
          for (const onboardSection of onboardingQuestions) {
            if (sections?.id === onboardSection?.id) finalOnboarding?.push({ ...sections, advisor_comment: onboardSection?.advisor_comment })
          }
        }
      } catch (error) {}

      return finalOnboarding
    } else {
      return null
    }
  }

  const returnPreviousOnboarding = () => {
    if (currentProfile?.onboarding) {
      const finalOnboarding = []

      for (const sections of childOnboardingQuestions) {
        for (const onboardSection of currentProfile?.onboarding) {
          if (sections?.id === onboardSection?.id)
            finalOnboarding?.push({ id: sections?.id - 1, main_statement: sections?.question, main_statement_answer: onboardSection?.answer })
        }
      }

      return finalOnboarding
    } else {
      return null
    }
  }

  useEffect(() => {
    if (currentProfile) {
      const profileInfo = {
        full_name: currentProfile?.full_name,
        age: currentProfile?.age,
        birth_date: currentProfile?.birth_date,
        languages: currentProfile?.languages,
        concerns: currentProfile?.concerns,
        behavior: currentProfile?.questionnaire?.child_info?.behavior,
        parent_preferred_language: currentProfile?.parent?.preferred_language,
      }

      const clientInfo = {
        full_name: currentProfile?.parent?.full_name,
        country: currentProfile?.parent?.country,
      }

      const advisorInfo = {
        full_name: currentProfile?.advisor,
      }

      const questionnaire = {
        familyDynamics: currentProfile?.questionnaire?.family_dynamics,
        parentalWellbeing: currentProfile?.questionnaire?.parental_wellbeing,
        questionnaire: returnNewOnboarding() || returnPreviousOnboarding(),
      }

      setProfileInfo(profileInfo)
      setClientInfo(clientInfo)
      setAdvisorInfo(advisorInfo)
      setQuestionnaire(questionnaire)
    }
  }, [currentProfile])

  return (
    <Modal open={open} onClose={onClose}>
      <Box height={['90vh']} variant="modal-container">
        <Flex width="100%" justifyContent="flex-end" mr={[2]} mt={[2]}>
          <CloseIcon className={Styles.header__icon} onClick={onClose} />
        </Flex>
        {!currentProfile ? (
          <Spinner />
        ) : (
          <Box className={Styles.scroll}>
            <div className={Styles.content}>
              <GeneralProfileInformation profile={profileInfo} client={clientInfo} advisor={advisorInfo} />
              <ParentProfileQuestionnairePartWithTranslation profile={profileInfo} questionnaire={questionnaire} />
            </div>
          </Box>
        )}
      </Box>
    </Modal>
  )
}
