import { objectiveTypes } from 'constants/index'
import { Progress } from 'pages/ShardPage/TreatmentPlan/slice/types'
import * as yup from 'yup'
/**
 * "test"
 * true means the validation passes (no error message shown)
 * false means the validation fails (error message will be shown)
 */
export const progressFormSchema = (progress: Array<Partial<Progress | null>>) => {
  const dynamicSchema: any = {}
  progress.map((item, index) => {
    if (item) {
      dynamicSchema[`progress-note-${index}`] = yup.string().test(`progress-note-${index}`, 'Note can not be more than 500 characters', (value: any) => {
        if (value?.length > 500) {
          return false
        }
        return true
      })
      dynamicSchema[`progress-objective-${index}`] = yup
        .object()
        .nullable()
        .test(`progress-objective-${index}`, 'Objective is required', (value: any) => {
          if (value?.id) {
            return true
          } else {
            if (!item?.objective?.id) {
              return false
            }
            return true
          }
        })

      dynamicSchema[`progress-current-level-${index}`] = yup
        .string()
        .test(`progress-current-level-${index}`, 'Current level is required', (value: string | undefined) => {
          let passedValidation = false
          if (item.objective?.type === objectiveTypes.QUANTITATIVE) {
            if (value || item.quantitative_value) {
              passedValidation = true
            } else {
              passedValidation = false
            }
          } else {
            passedValidation = true
          }
          return passedValidation
        })
        .test(`progress-current-level-${index}`, 'Current level must be between (0, 100)', (value: string | undefined) => {
          let passedValidation = false
          if (item.objective?.type === objectiveTypes.QUANTITATIVE) {
            // console.log(value, item.quantitative_value)
            if (value) {
              if (parseInt(value as string) < 0 || parseInt(value as string) > 100) {
                passedValidation = false
              } else {
                passedValidation = true
              }
            } else if (item.quantitative_value) {
              if (parseInt(item?.quantitative_value as string) < 0 || parseInt(item?.quantitative_value as string) > 100) {
                passedValidation = false
              } else {
                passedValidation = true
              }
            } else {
              passedValidation = true
            }
          } else {
            passedValidation = true
          }
          return passedValidation
        })
      dynamicSchema[`progress-improvement-${index}`] = yup
        .string()
        .test(`progress-improvement-${index}`, 'Improvement is required', (value: string | undefined) => {
          let passedValidation = false
          if (item.objective?.type === objectiveTypes.QUALITATIVE) {
            if (item.qualitative_value || value) {
              passedValidation = true
            } else {
              passedValidation = false
            }
          } else {
            passedValidation = true
          }
          return passedValidation
        })
        .test(`progress-improvement-${index}`, 'Improvement must be <= 255 characters', (value: string | undefined) => {
          let passedValidation = false
          if (item.objective?.type === objectiveTypes.QUALITATIVE) {
            if ((value && value.length > 255) || (item.qualitative_value && item.qualitative_value.length > 255)) {
              passedValidation = false
            } else {
              passedValidation = true
            }
          } else {
            passedValidation = true
          }
          return passedValidation
        })
    }
  })
  return yup.object({
    ...dynamicSchema,
  })
}
