import { useDispatch, useSelector } from 'react-redux'
import { selectParentTaskFormOpened } from '../selectors'
import { parentTaskActions } from '..'

export const useParentTaskFormController = () => {
  const parentTaskFormOpened = useSelector(selectParentTaskFormOpened)
  const dispatch = useDispatch()
  const openParentTaskForm = () => {
    dispatch(parentTaskActions.openParentTaskForm())
  }
  const closeParentTaskForm = () => {
    dispatch(parentTaskActions.closeParentTaskForm())
  }
  const toggleParentTaskForm = () => {
    dispatch(parentTaskActions.toggleParentForm())
  }
  return {
    openParentTaskForm,
    closeParentTaskForm,
    toggleParentTaskForm,
    parentTaskFormOpened,
  }
}
