import { format } from 'date-fns'

type Options = {
  date: string | undefined
  formatType?: string
}
export const dateFormatter = ({ date, formatType = 'PPpp' }: Options) => {
  try {
    if (!date) return null
    return format(new Date(date), formatType)
  } catch (error) {
    console.log(error)
    return null
  }
}

type CurrencyFormatterProps = {
  amount: number | undefined
  currency?: string
}
export const currencyFormatter = ({ amount = 0, currency = 'USD' }: CurrencyFormatterProps) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  })
  return formatter.format(amount)
}
