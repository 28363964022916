import { ReactNode, useState } from 'react'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Text } from 'components/Core'
import { CSSObject } from 'styled-components'

const StyledAccordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  backgroundColor: 'transparent',
  paddingLeft: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary expandIcon={<ExpandMore sx={{ fontSize: '1.5rem' }} />} {...props} />)(
  ({ theme }) => ({
    backgroundColor: 'transparent',
    border: 'none',
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'space-between',
    ':hover': {
      backgroundColor: theme.palette.grey[200],
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }),
)

const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: 'none',
}))

interface CustomAccordionProps {
  title: string
  children: ReactNode
  defaultExpanded?: boolean
  titleAlignment?: CSSObject['textAlign']
  accordionSummaryProps?: AccordionSummaryProps
}

export const CustomAccordion = ({ title, children, defaultExpanded = false, titleAlignment = 'right', accordionSummaryProps }: CustomAccordionProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded)

  const handleChange = (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded)
  }

  return (
    <StyledAccordion sx={{ width: '100%' }} expanded={expanded} onChange={handleChange}>
      <StyledAccordionSummary sx={{ gap: 2 }} aria-controls="panel-content" {...accordionSummaryProps}>
        <Text textAlign={titleAlignment} width="100%" variant="body1">
          {title}
        </Text>
      </StyledAccordionSummary>
      <StyledAccordionDetails sx={{ backgroundColor: 'white', borderRadius: '10px' }}>{children}</StyledAccordionDetails>
    </StyledAccordion>
  )
}
