import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { progressFormSchema } from './validation'
import type { Progress } from 'pages/ShardPage/TreatmentPlan/slice/types'
import { useMemo } from 'react'
import { useCreateProgressMutation } from 'pages/ShardPage/TreatmentPlan/slice/services'

type Options = {
  progresses: Array<Partial<Progress | null>>
  session_id: number
  handleClose: () => void
}
export const useProgressForm = ({ progresses, session_id, handleClose }: Options) => {
  const { control, getValues, reset, register, setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(progressFormSchema(progresses)),
    mode: 'onSubmit',
  })

  const prepareProgressToUpdate = useMemo(() => {
    return progresses
      .filter((progress) => progress !== null)
      .map((progress) => ({
        session_id,
        objective_id: progress?.objective?.id,
        quantitative_value: progress?.quantitative_value,
        qualitative_value: progress?.qualitative_value,
        progress_id: progress?.id,
        note: progress?.note,
        note_visible_to_parent: progress?.note_visible_to_parent,
      }))
      .filter((progress) => !(progress?.progress_id && !progress?.objective_id))
  }, [progresses])
  const [create, { isLoading, error }] = useCreateProgressMutation()
  const onSubmit = handleSubmit((data) => {
    create({
      data: prepareProgressToUpdate,
      closeModal: handleClose,
    })
  })

  return {
    control,
    formState,
    isLoading,
    getValues,
    reset,
    register,
    setValue,
    onSubmit,
  }
}
