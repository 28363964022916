import { Box, Button, CenterColumn, CenterRow, Container, Flex, Text } from 'components/Core'
import { useParentTaskFormController } from './slice/hooks'
import { useGetParentTasksQuery } from './slice/services'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { Add, Assignment } from '@mui/icons-material'
import { useEffect, useMemo, useState } from 'react'
import { Grid, Pagination, TextField } from '@mui/material'
import { ParentTaskList } from './ParentTaskList'
import { CustomAccordion } from 'components/shared/CustomAccordion'
import { ParentTaskForm } from './ParentTaskForm'
import { ProfileName } from 'components/ClientProfileComponents/GeneralInformation/ProfileName'
import { useSelector } from 'react-redux'
import { selectFilters, selectParentTask } from './slice/selectors'
import { useParentTaskSlice } from './slice'
import { usePagination } from 'hooks/usePagination'
import { theme } from 'utils/theme'
import { dateFilters, ParentTaskStatus, parentTaskStatusMap } from 'constants/index'
import { ParentStatusType, TFN } from 'utils/types/types'
import { ParentTaskDetail } from './ParentTaskDetail'
import { Filters } from './Filters'
import { useTranslation } from 'react-i18next'
import { useUserTypeCheck } from 'hooks/useUserTypeCheck'

type Props = {
  profile_id: number
  profile_name: string
  readOnly: boolean
  canChangeStatus?: boolean
  canComment?: boolean
  ProfileSelector?: () => JSX.Element
  expandAllAccordion?: boolean
  isArabic?: boolean
}
export const ParentTask = ({ profile_id, profile_name, readOnly, canComment, ProfileSelector, expandAllAccordion, canChangeStatus = false, isArabic }: Props) => {
  const { openParentTaskForm, closeParentTaskForm, parentTaskFormOpened } = useParentTaskFormController()
  const [autoFocus, setAutoFocus] = useState(false)
  const { isUserTherapist, isUserParent } = useUserTypeCheck()
  const { t } = useTranslation()
  const filters = useSelector(selectFilters)
  const [parentTaskId, setParentTaskId] = useState<number>()
  const [search, setSearch] = useState('')
  const { setEditParentTaskValue, removeDateFilter, addDateFilters } = useParentTaskSlice()
  const { page, setTotalItems, goToPage, totalPages } = usePagination()

  // Pagination currently disabled, and we are using hard 300 value as page_size on backend just for now
  const {
    data: allTasks,
    isLoading,
    isFetching,
  } = useGetParentTasksQuery(
    {
      profile_id,
      page,
      search,
      date_filter: filters.date,
    },
    { skip: !profile_id, refetchOnMountOrArgChange: isUserTherapist, refetchOnFocus: isUserParent },
  )

  useEffect(() => {
    if (isUserTherapist) addDateFilters(dateFilters.last7Days)
  }, [isUserTherapist])

  useEffect(() => {
    setTotalItems(allTasks?.count ?? 0)
  }, [allTasks?.count])
  const selectedTask = useSelector(selectParentTask)
  const showAddTask = useMemo(() => {
    if (readOnly) return false
    if (!parentTaskFormOpened && !allTasks?.count && !isFetching) {
      return true
    } else {
      return false
    }
  }, [isFetching, parentTaskFormOpened, allTasks?.count])

  const profileHasTaskCreatedFor = useMemo(() => {
    if (parentTaskFormOpened) {
      return false
    }
    if (allTasks && allTasks?.count > 0) {
      return true
    } else {
      return false
    }
  }, [allTasks, parentTaskFormOpened, isFetching])

  const justifyContent = useMemo(() => {
    let value = 'flex-start'
    if (parentTaskFormOpened) {
      value = 'flex-start'
    } else {
      if (isFetching) {
        value = 'center'
      } else {
        if (profileHasTaskCreatedFor) {
          value = 'flex-start'
        } else {
          value = 'center'
        }
      }
    }

    return value
  }, [isFetching, profileHasTaskCreatedFor, parentTaskFormOpened])

  return parentTaskId ? (
    <ParentTaskDetail
      setAutoFocus={setAutoFocus}
      autoFocus={autoFocus}
      canComment={canComment}
      isArabic={isArabic}
      t={t}
      onGoBack={() => setParentTaskId(undefined)}
      parentTaskId={parentTaskId}
      canChangeStatus={canChangeStatus}
      profile_id={profile_id}
    />
  ) : (
    <Container flexDirection="column" p={[2, 2, '20px']} backgroundColor="white">
      <CenterRow justifyContent="space-between" alignItems="flex-start !important" mb={[3, 3, 0]} width="100%">
        {ProfileSelector ? <ProfileSelector /> : <ProfileName name={profile_name} />}
        {!parentTaskFormOpened && !readOnly && (
          <CenterRow>
            <Filters
              setFilter={(value) => {
                addDateFilters(value)
              }}
              filters={[filters.date]}
              removeFilter={() => {
                removeDateFilter()
              }}
            />
          </CenterRow>
        )}
      </CenterRow>
      <Container
        width={['100%']}
        flexDirection={['column']}
        alignItems={parentTaskFormOpened ? 'flex-start' : 'center'}
        justifyContent={justifyContent}
        pb={20}
        minHeight={['90vh']}
      >
        {isFetching ? (
          <Spinner />
        ) : profileHasTaskCreatedFor ? (
          <Flex width="100%" flexDirection={['column']} gap={20}>
            <CenterRow borderBottom={1} pb={3} width={['100%']} justifyContent={['space-between']}>
              <Text variant="heading4">
                {t('treatmentPlanPlusTask:tasks')} ({allTasks?.count})
              </Text>
              {!readOnly && (
                <Button onClick={openParentTaskForm} minWidth="fit-content">
                  <Add />
                  <Text>Add Task</Text>
                </Button>
              )}
            </CenterRow>
            <Flex flexDirection={['column']} gap={20}>
              {(Object.values(ParentTaskStatus) as Array<ParentStatusType>).map(
                (status, index) =>
                  allTasks?.results?.[status]?.status && (
                    <CustomAccordion
                      accordionSummaryProps={{
                        sx: {
                          backgroundColor: theme.colors.black[100],
                          borderRadius: '10px',
                          px: 1,
                        },
                      }}
                      key={index}
                      title={`${t(`treatmentPlanPlusTask:${parentTaskStatusMap[allTasks?.results?.[status]?.status]}`)} (${allTasks?.results?.[status]?.count})`}
                      defaultExpanded={expandAllAccordion ?? index === 0}
                      titleAlignment={isArabic ? 'right' : 'left'}
                    >
                      <Grid container spacing={2} alignItems="stretch">
                        {/* <CenterRow key={index} justifyContent={['center', 'center', 'center', 'flex-start']} flexWrap={['wrap']} gap={20}> */}
                        {allTasks?.results?.[status]?.tasks?.map((item, index) => (
                          <Grid
                            sx={{
                              width: ['100%', '95%', '95%', '350px', '360px'],
                            }}
                            item
                            key={index}
                          >
                            <ParentTaskList
                              canChangeStatus={canChangeStatus}
                              profile_id={profile_id}
                              canComment={canComment}
                              readOnly={readOnly}
                              setEditTask={setEditParentTaskValue}
                              task={item}
                              onClick={() => setParentTaskId(item.id)}
                              t={t}
                              setAutoFocus={setAutoFocus}
                            />
                          </Grid>
                        ))}
                        {allTasks?.results?.[status]?.count === 0 && status === ParentTaskStatus.TODO && (
                          <Container width="100%" mt={4}>
                            {isUserParent ? (
                              <AllCaughtUp t={t} iconWidth={[120]} />
                            ) : (
                              <Text variant="heading4" textAlign="center">
                                No todos
                              </Text>
                            )}
                          </Container>
                        )}
                      </Grid>
                      {/* </CenterRow> */}
                    </CustomAccordion>
                  ),
              )}
              {/* <Pagination
                sx={{ direction: 'ltr' }}
                page={page}
                onChange={(_, value) => {
                  goToPage(value)
                }}
                count={totalPages}
              /> */}
            </Flex>
          </Flex>
        ) : parentTaskFormOpened ? (
          <ParentTaskForm task={selectedTask} closeForm={closeParentTaskForm} profile_id={profile_id} />
        ) : (
          readOnly && <AllCaughtUp t={t} />
        )}
        {showAddTask && (
          <Container flexGrow={1} flexDirection={['column']} gap={10}>
            <Text variant="heading4" textAlign={'center'} fontWeight="normal">
              No task found, create one by clicking Add Task button
            </Text>
            <Button onClick={openParentTaskForm}>
              <Add />
              <Text>Add Task</Text>
            </Button>
          </Container>
        )}
      </Container>
    </Container>
  )
}

type AllCaughtUpProps = {
  t: TFN
  iconWidth?: Array<number | string>
}
const AllCaughtUp = ({ t, iconWidth }: AllCaughtUpProps) => {
  return (
    <CenterColumn alignItems="center" height="100%">
      {/* <Assignment color="disabled" fontSize="large" /> */}
      <Box width={iconWidth ?? 150}>
        <img width={'100%'} height={'100%'} src="https://numuw-prod-public.s3.me-central-1.amazonaws.com/email_imgs/2.png" alt="empty goals" />
      </Box>
      <Text textAlign="center" color="textGray" variant="heading4">
        {t('treatmentPlanPlusTask:noTasksTitle')}
      </Text>
    </CenterColumn>
  )
}
