import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'
export type GetProfileResult = {
  full_name: string
  id: number | string
  profile_pic: string | null
}
export const { useGetAllParentProfilesQuery } = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    getAllParentProfiles: build.query<Array<GetProfileResult>, null>({
      query: () => ({
        url: apiRoutes.getAllProfiles,
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['ParentProfiles'],
    }),
  }),
})
