import history from 'lib/history'
import { toast } from 'react-toastify'
import { setIsAuth, setUser } from 'reducers/authSlice'
import { setLoading } from 'reducers/generalSlice'
import { setTimeZone } from 'reducers/profileSlice'
import { deleteCookie, setCookie, userTimeZone } from 'utils'

export const getFormatedLocalTime = (timestamp) => {
  const date = new Date(timestamp)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const period = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes

  return formattedHours + ':' + formattedMinutes + ' ' + period
}

export const capitalizeWord = (word) => {
  if (!word) {
    return
  } else {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }
}

//SET NOTIFICATION
export const notify = (message, isError = true, autoClose = true) => {
  if (isError)
    toast.error(message, {
      theme: 'light',
      autoClose,
    })
  if (!isError)
    toast.success(message, {
      theme: 'light',
      autoClose,
    })
}

export const loginAUser = (response, dispatch, impersonation = false) => {
  localStorage.setItem('token', response?.token)

  const language = localStorage.getItem('language')
  if (impersonation) localStorage.setItem('impersonated', response?.impersonated ?? false)

  if (!language) {
    localStorage.setItem('language', 'en')
  }

  dispatch(setIsAuth(true))
  dispatch(setUser(response))
  dispatch(setLoading(false))
  dispatch(setTimeZone(userTimeZone))

  const user = {
    user: {
      id: response.user?.id,
      user_type: response.user?.user_type,
      is_email_confirmed: response.user?.is_email_confirmed,
      timeZone: userTimeZone,
    },
    profile: response.profile,
    token: response.token,
  }
  console.log(user)

  setCookie('user', JSON.stringify(user), {
    // secure: true,
    // samesite: true,
    'max-age': 43200,
  })

  switch (response?.user?.user_type) {
    case 'parent':
      history.push(`/profile/parent/therapy`)
      break
    case 'therapist':
      history.push(`/profile/therapist/applications`)
      break
    case 'advisor':
      history.push(`/admin_panel/advisor/applications`)
      break
    case 'admin':
      history.push(`/admin_panel/admin/advisors`)
      break
    default:
      history.push(`/login`)
  }
}

export const stopImpersonation = () => {
  localStorage.removeItem('token')
  deleteCookie('user')
  window.location.href = '/'
  localStorage.removeItem('impersonated')
}
